import * as MD from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';

import * as Proto from '../../Protos/protos';
import { IApplicationState, IConnectedReduxProps } from '../../Store';
import { I18N } from '../../Store/I18n';
import { IPlayerState, setEndSelection, setStartSelection } from '../../Store/Player';
import { ITimelineState } from '../../Store/Timeline';
import { IUserState } from '../../Store/User';
import { IXMLState } from '../../Store/XML';
import { getDateInTz } from '../../Utils/Time';
import { isNull, isNullOrUndefined } from '../../Utils/Various';
import AnnotationDialog from './AnnotationDialog';
import ExtractionDialog from './ExtractionDialog';
import { TalkDialog } from './TalkDialog';
import { Annotations } from './Timeline/Annotations';
import Audience from './Timeline/Audience';
import AudiencesInfos from './Timeline/AudiencesInfos';
import { Background } from './Timeline/Background';
import { BackgroundSTG } from './Timeline/BackgroundSTG';
import { Broadcaster } from './Timeline/Broadcaster';
import { Declarative } from './Timeline/Declarative';
import { Music } from './Timeline/Music';
import { PopoverBackground } from './Timeline/PopoverBackground';
import { PopoverBackgroundSTG } from './Timeline/PopoverBackgroundSTG';
import { PopoverBroadcaster } from './Timeline/PopoverBroadcaster';
import { PopoverDeclarative } from './Timeline/PopoverDeclarative';
import { PopoverMusic } from './Timeline/PopoverMusic';
import { PopoverReadjusted } from './Timeline/PopoverReadjusted';
import { PopoverXML } from './Timeline/PopoverXML';
import { Readjusted } from './Timeline/Readjusted';
import { Talk } from './Timeline/Talk';
import TimeRuler from './Timeline/TimeRuler';
import { XML } from './Timeline/XML';

import styles from './Timeline.styles';
import { PopoverDeclarativeRadio } from './Timeline/PopoverDeclarativeRadio';
import { DeclarativeRadio } from './Timeline/DeclarativeRadio';
import { PopoverResultExtract } from './Timeline/PopoverResultsExtracts';
import { PopoverBrutPhonosense } from './Timeline/PopoverBrutPhonosense';
import { ResultsRaws } from './Timeline/BrutPhonosense';
import { ResultsExtracts } from './Timeline/ResultsExtracts';
import { PopoverResultProcessed } from './Timeline/PopoverResultsProcessed';
import { ResultProcessed } from './Timeline/ResultsProcessed';
import { PopoverLeonV1 } from './Timeline/PopoverLeonV1';
import { LeonV1 } from './Timeline/LeonV1';
import { PopoverARPP } from './Timeline/PopoverARPP';
import { ARPP } from './Timeline/ARPP';
import { CrossBucket } from './Timeline/CrossBucket';
import { PopoverCrossBucket } from './Timeline/PopoverCrossBucket';
import { PopoverCrossBucketstg } from './Timeline/PopoverCrossBucketstg';
import { CrossBucketstg } from './Timeline/CrossBucketstg';
import { PopoverMNMProcessed } from './Timeline/PopoverMNMProcessed';
import { MNMProcessed } from './Timeline/MNMProcessed';
import { PopoverMNMRaw } from './Timeline/PopoverMNMRaw';
import { MNMRaw } from './Timeline/MNMRaw';
import { PopoverMNMXB } from './Timeline/PopoverMNMXB';
import { MNMXB } from './Timeline/MNMXB';
import { PopoverMNIONLY } from './Timeline/PopoverMNIONLY';
import { MNIONLY } from './Timeline/MNIONLY';
import { PopoverSponsoring } from './Timeline/PopoverSponsoring';
import { Sponsoring } from './Timeline/Sponsoring';
import { PopoverOCR } from './Timeline/PopoverOCR';
import { OCR } from './Timeline/OCR';
import { PopoverOCRTitle } from './Timeline/PopoverOCRTitle';
import { OCRTitle } from './Timeline/OCRTitle';

interface IPropsFromState {
    i18n: I18N;
    player: IPlayerState;
    timeline: ITimelineState;
    user: IUserState;
    xml: IXMLState;
}

interface IPropsFromDispatch {
    setEndSelection: typeof setEndSelection;
    setStartSelection: typeof setStartSelection;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export class TimelineTimelineComponent extends React.Component<AllProps> {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    private centerBound: (this: HTMLElement, ev: any) => any;
    private currentTimeTimer = -1;
    private cursorCurrent: HTMLElement | null = null;
    private cursorSelectionEnd: HTMLElement | null = null;
    private cursorSelectionStart: HTMLElement | null = null;
    private lastKnownCurrentTime: Date | null = null;
    private leftBound: (this: HTMLElement, ev: any) => any;
    private scroller: Scrollbars | null = null;
    private selectionHighlight: HTMLElement | null = null;
    private timeTextElement: HTMLDivElement | null = null;
    private timeTextParagraphElement: HTMLDivElement | null = null;
    private trackingEnd = false;
    private trackingStart = false;
    private trackingDragTime: Date | null = null;
    private trackingOriginalDragTime: Date | null = null;
    private updatePositionBound: (this: HTMLElement, ev: any) => any;
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public constructor(props: AllProps) {
        super(props);
        this.centerBound = this.centerOnCursor.bind(this);
        this.leftBound = this.scrollLeft.bind(this);
        this.updatePositionBound = (ev: CustomEvent) => {
            this.setCursorPosition(ev.detail as Date);
        };
    }

    public render(): React.ReactNode {
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const seconds = Math.ceil((endTS - startTS) / 1000);
        const spans = Math.ceil(seconds / this.props.timeline.presentation.zoom.spanLength);
        const ms = endTS - startTS;
        const totalWidth = spans * this.props.timeline.presentation.zoom.spanWidth;

        window.setTimeout(() => {
            if (!isNullOrUndefined(this.scroller)) {
                this.scroller.forceUpdate();
            }
        }, 1000);

        if (
            this.cursorSelectionStart !== null &&
            this.cursorSelectionEnd !== null &&
            this.selectionHighlight !== null
        ) {
            if (this.props.player.startSelection !== null) {
                const delta = this.props.player.startSelection.getTime() - startTS;

                this.cursorSelectionStart.style.left = `${(delta / ms) * totalWidth - 4}px`;
                this.cursorSelectionStart.style.display = 'block';
            } else {
                this.cursorSelectionStart.style.display = 'none';
            }

            if (this.props.player.endSelection !== null) {
                const delta = this.props.player.endSelection.getTime() - startTS;

                this.cursorSelectionEnd.style.left = `${(delta / ms) * totalWidth - 4}px`;
                this.cursorSelectionEnd.style.display = 'block';
            } else {
                this.cursorSelectionEnd.style.display = 'none';
            }

            if (this.props.player.startSelection !== null && this.props.player.endSelection !== null) {
                const deltaStart = this.props.player.startSelection.getTime() - startTS;
                const deltaEnd = this.props.player.endSelection.getTime() - startTS;

                this.selectionHighlight.style.left = `${(deltaStart / ms) * totalWidth}px`;
                this.selectionHighlight.style.width = `${((deltaEnd - deltaStart) / ms) * totalWidth}px`;
                this.selectionHighlight.style.display = 'block';
            } else {
                this.selectionHighlight.style.display = 'none';
            }
        }

        return (
            <div className={this.props.classes.root} data-version={this.props.timeline.versionTag}>
                <div className={this.props.classes.legend}>
                    <div className={this.props.classes.legendItem + ' ' + this.props.classes.legendRuler}>
                        <div className={this.props.classes.legendRulerText} id='rulerText'>
                            <MD.Typography id='rulerTextParagraph'>--:--:--</MD.Typography>
                        </div>
                    </div>
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.AUDIENCE) !== -1 ? (
                        <Route component={AudiencesInfos} />
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.DECLARATIVE) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Declarative EPG')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.READJUSTED1) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend40}>
                            {this.props.i18n._('Readjusted EPG')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.BROADCASTER1) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend40}>
                            {this.props.i18n._('Broadcaster EPG')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(
                        Proto.mediaarchiver.EPGType.DECLARATIVERADIO1,
                    ) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend40}>
                            {this.props.i18n._('Declarative Radio EPG')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.EXTRACTRESULTS) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Archives')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.BRUTPHONO1) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend70}>
                            {this.props.i18n._('Brut reco')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(
                        Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET1,
                    ) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend70}>
                            {this.props.i18n._('Nikita monobucket')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.LEONV11) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend70}>
                            {this.props.i18n._('Leon V1')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MUSIC) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Music')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.BACKGROUND) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Background')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.BACKGROUNDSTG) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Background ES STG')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.ARPP) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Spots Publicitaires')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNMXB0) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Relevé musicaux avec musiques non identifiées')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNIONLY0) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Musiques non identifiées sans relevé musicaux')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.SPONSORING) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Sponsoring')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.CROSSBUCKET) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Relevé musicaux')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.CROSSBUCKETSTG) !==
                    -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Relevé musicaux stg')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.TALK) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Talk')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNM0) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('MPM Brut Phonosense')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNM1) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend20}>
                            {this.props.i18n._('Total musique détectée')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.OCR1) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend40}>
                            {this.props.i18n._('OCR')}
                        </div>
                    ) : (
                        ''
                    )}
                    {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.OCR1) !== -1 ? (
                        <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend40}>
                            {this.props.i18n._('OCR Title')}
                        </div>
                    ) : (
                        ''
                    )}
                    <div className={this.props.classes.legendItem + ' ' + this.props.classes.legend45}>
                        {this.props.i18n._('Annotations')}
                    </div>

                    {Array.from(this.props.xml.files.keys()).map((file, i) => (
                        <div
                            className={this.props.classes.legendItem + ' ' + this.props.classes.legendXML}
                            key={`xmlTitle_line_${i}`}
                        >
                            <a target='javascript:void(0);' title={file}>
                                {this.props.i18n.sprintf(this.props.i18n._('File #%1$d'), i + 1)}
                                <span>{file}</span>
                            </a>
                        </div>
                    ))}
                </div>
                <Scrollbars
                    id='scrollerContainer'
                    onMouseDown={this.onClickOnScroller.bind(this)}
                    onMouseMove={this.onMoveHoverScroller.bind(this)}
                    onMouseLeave={this.onLeaveScroller.bind(this)}
                    onWheel={this.onScrollWheel.bind(this)}
                    ref={(c) => {
                        this.scroller = c;
                    }}
                    renderThumbHorizontal={({ style, ...props }) => (
                        <div
                            {...props}
                            className={this.props.classes.scrollThumb}
                            style={{
                                ...style,
                            }}
                        />
                    )}
                    renderTrackHorizontal={({ style, ...props }) => (
                        <div
                            {...props}
                            className={this.props.classes.scrollTrack}
                            style={{
                                ...style,
                                maxHeight: style.height,
                            }}
                        />
                    )}
                >
                    <div
                        className={this.props.classes.scrollable}
                        style={{
                            height: this.props.timeline.height - 84,
                            width: `${totalWidth}px`,
                        }}
                    >
                        <div
                            className={this.props.classes.cursorsContainer}
                            style={{
                                width: `${totalWidth}px`,
                            }}
                        >
                            <div
                                className={this.props.classes.cursorCurrent}
                                ref={(c) => {
                                    this.cursorCurrent = c;
                                }}
                            />
                            <div
                                className={this.props.classes.cursorSelectionStart}
                                onClick={this.ignoredEvent.bind(this)}
                                onMouseDown={this.onStartMouseDown.bind(this)}
                                onMouseUp={this.onStartMouseUp.bind(this)}
                                style={{
                                    display: this.props.player.startSelection === null ? 'none' : 'block',
                                }}
                                ref={(c) => {
                                    this.cursorSelectionStart = c;
                                }}
                            >
                                <div />
                            </div>
                            <div
                                className={this.props.classes.cursorSelectionEnd}
                                onClick={this.ignoredEvent.bind(this)}
                                onMouseDown={this.onEndMouseDown.bind(this)}
                                onMouseUp={this.onEndMouseUp.bind(this)}
                                ref={(c) => {
                                    this.cursorSelectionEnd = c;
                                }}
                            >
                                <div />
                            </div>
                            <div
                                className={this.props.classes.selectionHighlight}
                                ref={(c) => {
                                    this.selectionHighlight = c;
                                }}
                            />
                        </div>
                        <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.ruler}>
                            <Route component={TimeRuler} />
                        </div>
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.AUDIENCE) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item100}>
                                <Route component={Audience} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.DECLARATIVE) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverDeclarative} />
                                <Route component={Declarative} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.READJUSTED1) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item40}>
                                <Route component={PopoverReadjusted} />
                                <Route component={Readjusted} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(
                            Proto.mediaarchiver.EPGType.BROADCASTER1,
                        ) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item40}>
                                <Route component={PopoverBroadcaster} />
                                <Route component={Broadcaster} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(
                            Proto.mediaarchiver.EPGType.DECLARATIVERADIO1,
                        ) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item40}>
                                <Route component={PopoverDeclarativeRadio} />
                                <Route component={DeclarativeRadio} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(
                            Proto.mediaarchiver.EPGType.EXTRACTRESULTS,
                        ) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverResultExtract} />
                                <Route component={ResultsExtracts} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.BRUTPHONO1) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item70}>
                                <Route component={PopoverBrutPhonosense} />
                                <Route component={ResultsRaws} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(
                            Proto.mediaarchiver.EPGType.NIKITAMONOBUCKET1,
                        ) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item70}>
                                <Route component={PopoverResultProcessed} />
                                <Route component={ResultProcessed} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.LEONV11) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item70}>
                                <Route component={PopoverLeonV1} />
                                <Route component={LeonV1} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MUSIC) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverMusic} />
                                <Route component={Music} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.BACKGROUND) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverBackground} />
                                <Route component={Background} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(
                            Proto.mediaarchiver.EPGType.BACKGROUNDSTG,
                        ) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverBackgroundSTG} />
                                <Route component={BackgroundSTG} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.ARPP) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverARPP} />
                                <Route component={ARPP} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNMXB0) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverMNMXB} />
                                <Route component={MNMXB} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNIONLY0) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverMNIONLY} />
                                <Route component={MNIONLY} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.SPONSORING) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverSponsoring} />
                                <Route component={Sponsoring} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.CROSSBUCKET) !==
                        -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverCrossBucket} />
                                <Route component={CrossBucket} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(
                            Proto.mediaarchiver.EPGType.CROSSBUCKETSTG,
                        ) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverCrossBucketstg} />
                                <Route component={CrossBucketstg} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.TALK) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={Talk} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNM0) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverMNMRaw} />
                                <Route component={MNMRaw} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.MNM1) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item20}>
                                <Route component={PopoverMNMProcessed} />
                                <Route component={MNMProcessed} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.OCR1) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item40}>
                                <Route component={PopoverOCR} />
                                <Route component={OCR} />
                            </div>
                        ) : (
                            ''
                        )}
                        {this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.OCR1) !== -1 ? (
                            <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item40}>
                                <Route component={PopoverOCRTitle} />
                                <Route component={OCRTitle} />
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={this.props.classes.scrollableItem + ' ' + this.props.classes.item45}>
                            <Route component={Annotations} />
                        </div>
                        {Array.from(this.props.xml.files.keys()).map((file, i) => (
                            <div
                                className={this.props.classes.scrollableItem + ' ' + this.props.classes.item40}
                                key={`xmlLine_${i}`}
                            >
                                <Route component={PopoverXML} />
                                <Route render={(props) => <XML {...props} file={file} />} />
                            </div>
                        ))}
                    </div>
                </Scrollbars>
                <Route component={AnnotationDialog} />
                <Route component={ExtractionDialog} />
                <Route component={TalkDialog} />
            </div>
        );
    }

    /*
    public UNSAFE_componentWillReceiveProps(props: AllProps): void {
        if (this.cursorCurrent && props.player.status !== this.props.player.status) {
            this.cursorCurrent.style.display = this.props.player.status === PlayerStatus.NULL ? 'none' : 'block';
        }
    }
    */

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public componentDidMount(): void {
        this.props.timeline.events.addEventListener<any>('center', this.centerBound);
        this.props.timeline.events.addEventListener<any>('left', this.leftBound);
        this.props.player.positionUpdateEventElem.addEventListener<any>('timeUpdate', this.updatePositionBound);
    }

    public componentWillUnmount(): void {
        this.props.timeline.events.removeEventListener<any>('center', this.centerBound);
        this.props.timeline.events.removeEventListener<any>('left', this.leftBound);
        this.props.player.positionUpdateEventElem.removeEventListener<any>('timeUpdate', this.updatePositionBound);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    private setCursorPosition(time: Date) {
        this.lastKnownCurrentTime = time;
        if (!this.cursorCurrent) {
            return;
        }
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const ms = endTS - startTS;
        const delta = time.getTime() - startTS;
        const left = (delta / ms) * 100;

        if (time.getTime() >= this.props.timeline.criterias.end.getTime()) {
            window.setTimeout(() => {
                if (!isNullOrUndefined(this.props.player.player)) {
                    this.props.player.player.pause();
                }
            }, 10);
        }
        this.cursorCurrent.style.display = 'block';
        this.cursorCurrent.style.left = `${left}%`;
        if (
            this.props.user.user.options.indexOf(Proto.mediaarchiver.UserOptions.USER_OPTION_TIMELINE_FOLLOW_CURSOR) !==
            -1
        ) {
            this.centerOnCursor();
        }
    }

    private centerOnCursor() {
        const scrollElem = document.getElementById('scrollerContainer');
        if (isNull(this.cursorCurrent) || isNull(scrollElem) || isNull(this.scroller)) {
            return;
        }
        const scrollerWidth = scrollElem.getBoundingClientRect().width;
        let dest = this.cursorCurrent.offsetLeft - scrollerWidth / 2;

        dest = dest < 0 ? 0 : dest;
        this.scroller.scrollLeft(dest);
        this.scroller.forceUpdate();
    }

    private scrollLeft() {
        if (!isNull(this.scroller)) {
            this.scroller.scrollLeft(0);
            this.scroller.forceUpdate();
        }
    }

    private onScrollWheel(ev: React.WheelEvent<Scrollbars>) {
        if (isNull(this.scroller)) {
            return;
        }
        this.scroller.scrollLeft(this.scroller.getValues().scrollLeft + ev.deltaY);
    }

    private onMoveHoverScroller(ev: React.MouseEvent<Scrollbars>) {
        const scrollElem = document.getElementById('scrollerContainer');
        const startTS = this.props.timeline.criterias.start.getTime();
        const endTS = this.props.timeline.criterias.end.getTime();
        const ms = endTS - startTS;
        const seconds = Math.ceil(ms / 1000);
        const spans = Math.ceil(seconds / this.props.timeline.presentation.zoom.spanLength);
        const totalWidth = spans * this.props.timeline.presentation.zoom.spanWidth;

        if (
            isNull(this.scroller) ||
            isNull(scrollElem) ||
            isNullOrUndefined(scrollElem.children[0]) ||
            isNullOrUndefined(scrollElem.children[0].children[0])
        ) {
            return;
        }
        const evX = this.scroller.getScrollLeft() + (ev.clientX - scrollElem.offsetLeft);
        if (this.currentTimeTimer !== -1) {
            window.clearTimeout(this.currentTimeTimer);
            this.currentTimeTimer = -1;
        }
        if (this.timeTextElement === null) {
            this.timeTextElement = document.getElementById('rulerText') as HTMLDivElement | null;
        }
        if (this.timeTextElement !== null) {
            this.timeTextElement.style.opacity = '1';
        }
        if (this.timeTextParagraphElement === null) {
            this.timeTextParagraphElement = document.getElementById(
                'rulerTextParagraph',
            ) as HTMLParagraphElement | null;
        }

        const time = startTS + (evX / totalWidth) * ms;

        if (this.timeTextParagraphElement !== null) {
            this.timeTextParagraphElement.innerText = moment(time).format('LTS.SSS');
        }

        const currentCursorTime = document.getElementById('currentCursorTime');

        if (isNull(currentCursorTime)) {
            return;
        }
        currentCursorTime.style.left = `${evX}px`;
        currentCursorTime.style.opacity = '1';
        if (this.trackingStart && !isNullOrUndefined(this.cursorSelectionStart)) {
            this.cursorSelectionStart.style.left = `${evX - 4}px`;
            this.trackingDragTime = new Date(time);
        }
        if (this.trackingEnd && !isNullOrUndefined(this.cursorSelectionEnd)) {
            this.cursorSelectionEnd.style.left = `${evX - 4}px`;
            this.trackingDragTime = new Date(time);
        }
    }

    private onLeaveScroller() {
        if (this.currentTimeTimer !== -1) {
            window.clearTimeout(this.currentTimeTimer);
            this.currentTimeTimer = -1;
        }
        if (this.timeTextElement === null) {
            this.timeTextElement = document.getElementById('rulerText') as HTMLDivElement | null;
        }
        if (this.timeTextElement !== null) {
            this.timeTextElement.style.opacity = '0';
        }

        const currentCursorTime = document.getElementById('currentCursorTime');

        if (isNull(currentCursorTime)) {
            return;
        }
        currentCursorTime.style.opacity = '0';
    }

    private onClickOnScroller(ev: React.MouseEvent<Scrollbars>) {
        const scrollElem = document.getElementById('scrollerContainer');
        const startTS = getDateInTz(
            this.props.timeline.criterias.start,
            this.props.timeline.criterias.timezone,
        ).getTime();
        const endTS = getDateInTz(this.props.timeline.criterias.end, this.props.timeline.criterias.timezone).getTime();

        const ms = endTS - startTS;
        const seconds = Math.ceil(ms / 1000);
        const spans = Math.ceil(seconds / this.props.timeline.presentation.zoom.spanLength);
        const totalWidth = spans * this.props.timeline.presentation.zoom.spanWidth;

        if (
            !isNullOrUndefined(ev.target) &&
            !isNullOrUndefined((ev.target as Element).hasAttribute) &&
            (ev.target as Element).hasAttribute('data-donottrackscroller')
        ) {
            return;
        }

        if (
            isNull(this.scroller) ||
            isNull(scrollElem) ||
            isNullOrUndefined(scrollElem.children[0]) ||
            isNullOrUndefined(scrollElem.children[0].children[0])
        ) {
            return;
        }
        const evX = this.scroller.getScrollLeft() + (ev.clientX - scrollElem.offsetLeft);
        if (this.currentTimeTimer !== -1) {
            window.clearTimeout(this.currentTimeTimer);
            this.currentTimeTimer = -1;
        }
        if (!isNullOrUndefined(this.props.player.player)) {
            this.props.player.player.start(new Date(startTS + (evX / totalWidth) * ms), new Date(endTS));
        }
    }

    private onStartMouseDown(ev: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        ev.stopPropagation();
        this.trackingDragTime = null;
        this.trackingStart = true;
        this.trackingOriginalDragTime = this.props.player.startSelection;
    }

    private onStartMouseUp(ev: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        ev.stopPropagation();
        if (!isNullOrUndefined(this.trackingDragTime)) {
            if (
                !isNull(this.props.player.endSelection) &&
                this.props.player.endSelection.getTime() < this.trackingDragTime.getTime() + 10
            ) {
                this.props.setStartSelection(this.trackingOriginalDragTime);
            } else {
                this.props.setStartSelection(this.trackingDragTime);
                if (!isNull(this.props.player.player)) {
                    this.props.player.player.start(new Date(this.trackingDragTime), this.props.timeline.criterias.end);
                }
            }
        }
        this.trackingDragTime = null;
        this.trackingStart = false;
        this.trackingOriginalDragTime = null;
    }

    private onEndMouseDown(ev: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        ev.stopPropagation();
        this.trackingDragTime = null;
        this.trackingEnd = true;
        this.trackingOriginalDragTime = this.props.player.endSelection;
    }

    private onEndMouseUp(ev: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        ev.stopPropagation();
        if (!isNullOrUndefined(this.trackingDragTime)) {
            if (
                !isNull(this.props.player.startSelection) &&
                this.props.player.startSelection.getTime() > this.trackingDragTime.getTime() - 10
            ) {
                this.props.setEndSelection(this.trackingOriginalDragTime);
            } else {
                this.props.setEndSelection(this.trackingDragTime);
                if (!isNull(this.props.player.player)) {
                    this.props.player.player.start(new Date(this.trackingDragTime), this.props.timeline.criterias.end);
                }
            }
        }
        this.trackingDragTime = null;
        this.trackingEnd = false;
        this.trackingOriginalDragTime = null;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private ignoredEvent(ev: any): void {
        if (!isNullOrUndefined(ev) && 'stopPropagation' in ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
    }
}

const mapStateToProps = ({ i18n, player, timeline, user, xml }: IApplicationState) => ({
    i18n: i18n.i18n,
    player,
    timeline,
    user,
    xml,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setEndSelection: (time: Date | null) => dispatch(setEndSelection(time)),
    setStartSelection: (time: Date | null) => dispatch(setStartSelection(time)),
});

export const TimelineTimeline = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MD.withStyles(styles)(TimelineTimelineComponent));
