import { Reducer } from 'redux';

import { IXMLState, XMLActionTypes } from './Types';
import { TypedStorage } from '../../Utils/TypedStorage';

const initialState: IXMLState = {
    currentFile: '',
    files: new Map(),
    loadError: '',
};

const reducer: Reducer<IXMLState> = (state = initialState, action) => {
    switch (action.type) {
        case XMLActionTypes.ADD_FILE: {
            const files = state.files;

            files.set(action.payload[0], action.payload[1]);

            /*
            const mem1 = TypedStorage.get('xmlEPG1', new Map());
            const mem2 = TypedStorage.get('xmlEPG2', new Map());
            const mem3 = TypedStorage.get('xmlEPG3', new Map());

            if (!mem1.has(action.payload[0]) && !mem2.has(action.payload[0]) && !mem3.has(action.payload[0])) {
                if (mem1.size === 0 && mem2.size === 0 && mem3.size === 0) {
                    TypedStorage.set('xmlEPG1', new Map().set(action.payload[0], action.payload[1]));
                } else if (mem1.size !== 0 && mem2.size === 0 && mem3.size === 0) {
                    TypedStorage.set('xmlEPG2', new Map().set(action.payload[0], action.payload[1]));
                } else if (mem1.size !== 0 && mem2.size !== 0 && mem3.size === 0) {
                    TypedStorage.set('xmlEPG3', new Map().set(action.payload[0], action.payload[1]));
                } else if (mem1.size !== 0 && mem2.size !== 0 && mem3.size !== 0) {
                    TypedStorage.set('xmlEPG1', mem2);
                    TypedStorage.set('xmlEPG2', mem3);
                    TypedStorage.set('xmlEPG3', new Map().set(action.payload[0], action.payload[1]));
                } else {
                    TypedStorage.del('xmlEPG1');
                    TypedStorage.del('xmlEPG2');
                    TypedStorage.del('xmlEPG3');
                    TypedStorage.set('xmlEPG1', new Map().set(action.payload[0], action.payload[1]));
                }
            }*/

            return {
                ...state,
                files,
            };
        }

        case XMLActionTypes.LOAD_FILE_STORAGE: {
            const files = state.files;
            /*
            const mem1 = TypedStorage.get('xmlEPG1', new Map());
            const mem2 = TypedStorage.get('xmlEPG2', new Map());
            const mem3 = TypedStorage.get('xmlEPG3', new Map());

            let currentFile = '';

            if (mem1.size > 0) {
                const mem1val = Array.from(mem1.values());
                const mem1key = Array.from(mem1.keys());
                currentFile = mem1key[0];

                files.set(mem1key[0], mem1val[0]);
            }

            if (mem2.size > 0) {
                const mem1val = Array.from(mem2.values());
                const mem1key = Array.from(mem2.keys());

                files.set(mem1key[0], mem1val[0]);
            }
            if (mem3.size > 0) {
                const mem1val = Array.from(mem3.values());
                const mem1key = Array.from(mem3.keys());

                files.set(mem1key[0], mem1val[0]);
            }
            */
            return {
                ...state,
                files,
            };
        }

        case XMLActionTypes.CHECK_LIST: {
            const files = state.files;
            /*
            const mem1 = TypedStorage.get('xmlEPG1', new Map());
            const mem2 = TypedStorage.get('xmlEPG2', new Map());
            const mem3 = TypedStorage.get('xmlEPG3', new Map());
*/
            const debut = action.payload[0];
            const fin = action.payload[1];

            files.forEach((v, k) => {
                const keyFile = Object.keys(v[0]).map((v) => parseInt(v, 10));
                if (
                    !keyFile.some((v) => {
                        return v > debut.getTime() && v < fin.getTime();
                    })
                ) {
                    files.delete(k);
                }
            });
            /*
            if (mem1.size > 0) {
                const mem1val = Array.from(mem1.values());

                const keyFile = Object.keys(mem1val[0][0]).map((v) => parseInt(v, 10));

                if (
                    !keyFile.some((v) => {
                        return v > debut.getTime() && v < fin.getTime();
                    })
                ) {
                    TypedStorage.del('xmlEPG1');
                }
            }

            if (mem2.size > 0) {
                const mem1val = Array.from(mem2.values());

                const keyFile = Object.keys(mem1val[0][0]).map((v) => parseInt(v, 10));
                if (
                    !keyFile.some((v) => {
                        return v > debut.getTime() && v < fin.getTime();
                    })
                ) {
                    TypedStorage.del('xmlEPG2');
                }
            }
            if (mem3.size > 0) {
                const mem1val = Array.from(mem3.values());

                const keyFile = Object.keys(mem1val[0][0]).map((v) => parseInt(v, 10));
                if (
                    !keyFile.some((v) => {
                        return v > debut.getTime() && v < fin.getTime();
                    })
                ) {
                    TypedStorage.del('xmlEPG3');
                }
            }*/
            if (files.size === 0) {
                return {
                    ...state,
                    currentFile: '',
                    files: new Map(),
                };
            } else {
                return {
                    ...state,
                    currentFile: files.keys().next().value,
                    files,
                };
            }
        }
        case XMLActionTypes.CLEAR_LIST: {
            const files = state.files;

            TypedStorage.del('xmlEPG1');
            TypedStorage.del('xmlEPG2');
            TypedStorage.del('xmlEPG3');

            files.delete(action.payload);
            return {
                ...state,
                currentFile: '',
                files: new Map(),
            };
        }

        case XMLActionTypes.SET_CURRENT_FILE: {
            return {
                ...state,
                currentFile: action.payload,
            };
        }

        case XMLActionTypes.SET_ERROR: {
            return {
                ...state,
                loadError: action.payload,
            };
        }

        default: {
            return state;
        }
    }
};

export { reducer as XMLReducer };
