import * as MD from '@material-ui/core';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { IApplicationState, IConnectedReduxProps } from '../../../Store';
import { I18N } from '../../../Store/I18n/Types';
import { ITimelinePopoversState } from '../../../Store/TimelinePopovers/Types';
import { getTheme } from '../../../Themes';
import { limitText } from '../../../Utils/String';
import { blueGrey, green, lightBlue, lightGreen } from '@material-ui/core/colors';

interface IPropsFromState {
    i18n: I18N;
    timelinePopovers: ITimelinePopoversState;
}

type AllProps = MD.WithStyles<typeof styles> & IPropsFromState & RouteComponentProps<{}> & IConnectedReduxProps;

const theme = getTheme();

const styles = MD.createStyles({
    popover: {
        display: 'none',
        pointerEvents: 'none',
        position: 'fixed',
        zIndex: 2000,
    },
    popoverContent: {
        '& h5': {
            fontSize: '1.5em', //'1em',
        },
        '& h6': {
            fontSize: '1.2em', //'0.7em',
            fontStyle: 'italic',
            marginTop: 5,
            textTransform: 'uppercase',
        },
        '& p': {
            fontSize: '1.2em', //'0.7em',
            marginTop: 10,
        },
        flex: '1 0 auto',
        wordWrap: 'break-word',
    },
    popoverCover: {
        '& img': {
            width: '100%',
        },
        width: 100,
    },
    popoverDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: 340,
    },
    prgChip: {
        fontSize: '0.7em',
        height: '1.5em',
        margin: theme.spacing(1),
        marginLeft: 0,
        textTransform: 'uppercase',
    },
});

export class PopoverOCRTitleComponent extends React.Component<AllProps> {
    public render(): React.ReactNode {
        return (
            <MD.Card className={this.props.classes.popover} id='ocrTitlePopover'>
                <div className={this.props.classes.popoverDetails}>
                    <MD.CardContent className={this.props.classes.popoverContent}>
                        <MD.Typography component='h5' variant='h5'>
                            {limitText(
                                this.props.timelinePopovers.popoverOCRTitle
                                    ? this.props.timelinePopovers.popoverOCRTitle.title
                                    : '',
                                150,
                            )}
                        </MD.Typography>
                        {this.props.timelinePopovers.popoverOCRTitle &&
                        this.props.timelinePopovers.popoverOCRTitle.category ? (
                            <MD.Typography
                                component='h6'
                                style={{
                                    color:
                                        this.props.timelinePopovers.popoverOCRTitle.category === 'speaker'
                                            ? lightBlue[400]
                                            : lightGreen[400],
                                }}
                                variant='h6'
                            >
                                {this.props.timelinePopovers.popoverOCRTitle
                                    ? this.props.timelinePopovers.popoverOCRTitle.category
                                    : ''}
                            </MD.Typography>
                        ) : (
                            ''
                        )}
                    </MD.CardContent>
                </div>
            </MD.Card>
        );
    }
}

const mapStateToProps = ({ i18n, timelinePopovers }: IApplicationState) => ({
    i18n: i18n.i18n,
    timelinePopovers,
});

export const PopoverOCRTitle = connect(mapStateToProps)(MD.withStyles(styles)(PopoverOCRTitleComponent));
