import { IAudienceMinuteState, IEPGProgramState } from '../Timeline/Types';

export enum TimelinePopoversActionTypes {
    SET_ARPP_POPOVER = '@@timeline/SET_ARPP_POPOVER',
    SET_MNMXB_POPOVER = '@@timeline/SET_MNMXB_POPOVER',
    SET_MNIONLY_POPOVER = '@@timeline/SET_MNIONLY_POPOVER',
    SET_SPONSORING_POPOVER = '@@timeline/SET_SPONSORING_POPOVER',
    SET_BACKGROUND_POPOVER = '@@timeline/SET_BACKGROUND_POPOVER',
    SET_BACKGROUND_STG_POPOVER = '@@timeline/SET_BACKGROUND_STG_POPOVER',
    SET_BROADCASTER_POPOVER = '@@timeline/SET_BROADCASTER_POPOVER',
    SET_CROSSBUCKET_POPOVER = '@@timeline/SET_CROSSBUCKET_POPOVER',
    SET_CROSSBUCKET_STG_POPOVER = '@@timeline/SET_CROSSBUCKET_STG_POPOVER',
    SET_CURRENT_MINUTE = '@@timeline/SET_CURRENT_MINUTE',
    SET_DECLARATIVE_POPOVER = '@@timeline/SET_DECLARATIVE_POPOVER',
    SET_DECLARATIVERADIO_POPOVER = '@@timeline/SET_DECLARATIVERADIO_POPOVER',
    SET_MNM_RAW_POPOVER = '@@timeline/SET_MNM_RAW_POPOVER',
    SET_MNM_PROCESSED_POPOVER = '@@timeline/SET_MNM_PROCESSED_POPOVER',
    SET_MUSIC_POPOVER = '@@timeline/SET_MUSIC_POPOVER',
    SET_READJUSTED_POPOVER = '@@timeline/SET_READJUSTED_POPOVER',
    SET_RESULT_EXTRACT_POPOVER = '@@timeline/SET_RESULT_EXTRACT_POPOVER',
    SET_RESULT_PROCESSED_POPOVER = '@@timeline/SET_RESULT_PROCESSED_POPOVER',
    SET_RESULT_PROCESSED_STG_POPOVER = '@@timeline/SET_RESULT_PROCESSED_STG_POPOVER',
    SET_RESULT_RAW_POPOVER = '@@timeline/SET_RESULT_RAW_POPOVER',
    SET_TALK_POPOVER = '@@timeline/SET_TALK_POPOVER',
    SET_XML_POPOVER = '@@timeline/SET_XML_POPOVER',
    SET_OCR_POPOVER = '@@timeline/SET_OCR_POPOVER',
    SET_OCR_TITLE_POPOVER = '@@timeline/SET_OCR_TITLE_POPOVER',
}

export interface ITimelinePopoversState {
    readonly currentAudienceMinute: IAudienceMinuteState;
    readonly popoverARPP: IEPGProgramState | null;
    readonly popoverBackground: IEPGProgramState | null;
    readonly popoverBackgroundSTG: IEPGProgramState | null;
    readonly popoverBroadcaster: IEPGProgramState | null;
    readonly popoverBrutPhonosense: IEPGProgramState | null;
    readonly popoverCrossBucket: IEPGProgramState | null;
    readonly popoverCrossBucketstg: IEPGProgramState | null;
    readonly popoverDeclarative: IEPGProgramState | null;
    readonly popoverDeclarativeRadio: IEPGProgramState | null;
    readonly popoverLeonV1: IEPGProgramState | null;
    readonly popoverMNIONLY: IEPGProgramState | null;
    readonly popoverMNMProcessed: IEPGProgramState | null;
    readonly popoverMNMRaw: IEPGProgramState | null;
    readonly popoverMNMXB: IEPGProgramState | null;
    readonly popoverMusic: IEPGProgramState | null;
    readonly popoverOCR: IEPGProgramState | null;
    readonly popoverOCRTitle: IEPGProgramState | null;
    readonly popoverReadjusted: IEPGProgramState | null;
    readonly popoverResultExtract: IEPGProgramState | null;
    readonly popoverResultProcessed: IEPGProgramState | null;
    readonly popoverSponsoring: IEPGProgramState | null;
    readonly popoverTalk: IEPGProgramState | null;
    readonly popoverXML: IEPGProgramState | null;
}

export interface ISetBackgroundPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_BACKGROUND_POPOVER;
}

export interface ISetBackgroundSTGPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_BACKGROUND_STG_POPOVER;
}

export interface ISetOcrPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_OCR_POPOVER;
}
export interface ISetOcrTitlePopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_OCR_TITLE_POPOVER;
}

export interface ISetBroadcasterPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_BROADCASTER_POPOVER;
}

export interface ISetCurrentMinuteAction {
    payload: IAudienceMinuteState | null;
    type: TimelinePopoversActionTypes.SET_CURRENT_MINUTE;
}
export interface ISetCrossBucketPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_CROSSBUCKET_POPOVER;
}
export interface ISetCrossBucketstgPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_CROSSBUCKET_STG_POPOVER;
}
export interface ISetDeclarativePopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_DECLARATIVE_POPOVER;
}

export interface ISetDeclarativeRadioPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_DECLARATIVERADIO_POPOVER;
}

export interface ISetMNMRawPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_MNM_RAW_POPOVER;
}
export interface ISetMNMProcessedPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_MNM_PROCESSED_POPOVER;
}

export interface ISetMusicPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_MUSIC_POPOVER;
}

export interface ISetReadjustedPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_READJUSTED_POPOVER;
}

export interface ISetTalkPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_TALK_POPOVER;
}

export interface ISetXMLPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_XML_POPOVER;
}

export interface ISetBrutPhonosensePopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_RESULT_RAW_POPOVER;
}

export interface ISetResultProcessedPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_RESULT_PROCESSED_POPOVER;
}
export interface ISetLeonV1PopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_RESULT_PROCESSED_STG_POPOVER;
}

export interface ISetResultExtractPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_RESULT_EXTRACT_POPOVER;
}

export interface ISetARPPPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_ARPP_POPOVER;
}

export interface ISetMNMXBPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_MNMXB_POPOVER;
}
export interface ISetMNIONLYPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_MNIONLY_POPOVER;
}

export interface ISetSponsoringPopoverAction {
    payload: IEPGProgramState | null;
    type: TimelinePopoversActionTypes.SET_SPONSORING_POPOVER;
}
