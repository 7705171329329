import * as Proto from '../../Protos/protos';
import { MediaTypes } from '../Medias/Types';

export const groupsForWarningDisplay: number[] = [2, 152];
export const dateWarning: number = 1667260800000 - 3600000;
export const warningMsg =
    "L'accès aux archives à cette date peut engendrer des lenteurs lors de la lecture et la navigation";

export enum TimelineActionTypes {
    CENTER = '@@timeline/CENTER',
    HIDE = '@@timeline/HIDE',
    HIDE_REPORT_BUG = '@@timeline/HIDE_REPORT_BUG',
    MOVE_STARTCONTENT_STEP = '@@timeline/MOVE_STARTCONTENT_STEP',
    REPORT_BUG = '@@timeline/REPORT_BUG',
    SET_AUDIENCE_MODE = '@@timeline/SET_AUDIENCE_MODE',
    SET_AUDIENCE_VOSDAL = '@@timeline/SET_AUDIENCE_VOSDAL',
    SET_CALENDAR_DURATION = '@@timeline/SET_CALENDAR_DURATION',
    SET_CALENDAR_START = '@@timeline/SET_CALENDAR_START',
    SET_CALENDAR_START_WARNING = '@@timeline/SET_CALENDAR_START_WARNING',
    SET_EPGS = '@@timeline/SET_EPGS',
    SET_HEIGHT = '@@timeline/SET_HEIGHT',
    SET_PLAYBACK_SPEED = '@@timeline/SET_PLAYBACK_SPEED',
    SET_REPORTING = '@@timeline/SET_REPORTING',
    SET_REPORT_BUG_ERRORS = '@@timeline/SET_REPORT_BUG_ERRORS',
    SET_STORAGE_BUG_SEEN = '@@timeline/SET_STORAGE_BUG_SEEN',
    SHOW = '@@timeline/SHOW',
    SHOW_REPORT_BUG = '@@timeline/SHOW_REPORT_BUG',
    SHOW_STORAGE_BUG = '@@timeline/SHOW_STORAGE_BUG',
    UPDATE = '@@timeline/UPDATE',
    UPDATE_CRITERIAS = '@@timeline/UPDATE_CRITERIAS',
    UPDATE_TIMELINE = '@@timeline/UPDATE_TIMELINE',
    WIP = '@@timeline/WIP',
    ZOOM_IN = '@@timeline/ZOOM_IN',
    ZOOM_OUT = '@@timeline/ZOOM_OUT',
}

export interface IZoom {
    readonly isDefault: boolean;
    readonly isMax: boolean;
    readonly isMin: boolean;
    // Seconds in a span
    readonly spanLength: number;
    // Subsections in a span
    readonly spanSubsections: number;
    // Span width in pixels
    readonly spanWidth: number;
}

export interface ITimelineCalendarState {
    readonly duration: number;
    readonly start: Date;
    readonly startWarning: string;
}

export interface ITimelineCriteriasState {
    readonly active: boolean;
    readonly archiveTitle: string;
    readonly calendar: ITimelineCalendarState;
    readonly contentTypes: Proto.mediaarchiver.EPGType[];
    readonly end: Date;
    readonly dataToken: string;
    readonly media: number;
    readonly mediaName: string;
    readonly mediaType: MediaTypes;
    readonly playerStart: Date;
    readonly sender: string;
    readonly show: boolean;
    readonly start: Date;
    readonly streamerToken: string;
    readonly timezone: string;
    readonly type: string;
    readonly incrementGenerate: number;
}

export interface IEPGProgramState {
    readonly category: string;
    readonly categoryText?: string;
    readonly duration: number;
    readonly metas?: { [k: string]: string };
    readonly realDuration: number;
    readonly realStart: Date;
    readonly start: Date;
    readonly subtitle?: string;
    readonly synopsis?: string;
    readonly title: string;
    readonly vignette?: string;
}

export interface IEPGState {
    [minute: number]: IEPGProgramState;
}

export interface IAudienceMinuteState {
    readonly averageRate: number;
    readonly marketShare: number;
    readonly minute: Date | null;
    readonly weightedBasis: number;
}

export interface IAudiencesState {
    [minute: number]: IAudienceMinuteState;
    max: number;
}

export interface IReportedBugInfos {
    company: string;
    mail: string;
    message: string;
    name: string;
    phone: string;
}

export interface ITimelineDataState2 {
    [protoID: number]: { epgs: IEPGState[] };
}

export interface ITimelineDataState {
    readonly annotationsEPG: IEPGState;
    readonly arppEPG: IEPGState;
    readonly audienceEPG: IEPGState;
    readonly backgroundEPG: IEPGState;
    readonly backgroundstgEPG: IEPGState;
    readonly broadcasterEPG: IEPGState;
    readonly broadcasterEPGDetails: IEPGState;
    readonly brutPhonosenseEPG0: IEPGState;
    readonly brutPhonosenseEPG1: IEPGState;
    readonly brutPhonosenseEPG2: IEPGState;
    readonly brutPhonosenseEPG3: IEPGState;
    readonly crossbucketEPG: IEPGState;
    readonly crossbucketstgEPG: IEPGState;
    readonly declarativeEPG: IEPGState;
    readonly declarativeRadioEPG: IEPGState;
    readonly declarativeRadioEPGDetails: IEPGState;
    readonly extractResultEPG: IEPGState;
    readonly leonV1EPG0: IEPGState;
    readonly leonV1EPG1: IEPGState;
    readonly leonV1EPG2: IEPGState;
    readonly leonV1EPG3: IEPGState;
    readonly mnionlyEPG: IEPGState;
    readonly mnmProcessedEPG: IEPGState;
    readonly mnmRawEPG: IEPGState;
    readonly mnmxbEPG: IEPGState;
    readonly musicEPG: IEPGState;
    readonly ocrEPG: IEPGState;
    readonly ocrEPGDetail: IEPGState;
    readonly ocrTitleEPG: IEPGState;
    readonly ocrTitleEPGDetail: IEPGState;
    readonly processedResultEPG0: IEPGState;
    readonly processedResultEPG1: IEPGState;
    readonly processedResultEPG2: IEPGState;
    readonly processedResultEPG3: IEPGState;
    readonly readjustedEPG: IEPGState;
    readonly readjustedEPGDetails: IEPGState;
    readonly sponsoringEPG: IEPGState;
    readonly sttEPG: IEPGState;
    readonly talkEPG: IEPGState;
}

export interface ITimelinePresentationState {
    readonly audienceMode: string;
    readonly audienceWithVOSDAL: boolean;
    readonly playbackSpeed: number;
    readonly zoom: IZoom;
}

export interface ITimelineState {
    readonly criterias: ITimelineCriteriasState;
    readonly criteriasHash: string;
    readonly data: ITimelineDataState;
    readonly data2: ITimelineDataState2;
    readonly events: HTMLElement;
    readonly height: number;
    readonly needZoomUpdate: boolean;
    readonly popoverBroadcaster: IEPGProgramState | null;
    readonly popoverDeclarative: IEPGProgramState | null;
    readonly popoverDeclarativeRadio: IEPGProgramState | null;
    readonly popoverReadjusted: IEPGProgramState | null;
    readonly presentation: ITimelinePresentationState;
    readonly ready: boolean;
    readonly reportBugErrors: Map<string, string>;
    readonly reportingBug: boolean;
    readonly showReportBug: boolean;
    readonly startContentStep: number;
    readonly storageBugShow: boolean;
    readonly versionTag: string;
    readonly workInProgress: boolean;
}

export interface ICenterAction {
    type: TimelineActionTypes.CENTER;
}

export interface IHideAction {
    type: TimelineActionTypes.HIDE;
}

export interface IHideReportBugAction {
    type: TimelineActionTypes.HIDE_REPORT_BUG;
}

export interface IMoveStartContentStepAction {
    payload: number;
    type: TimelineActionTypes.MOVE_STARTCONTENT_STEP;
}

export interface IReportBugAction {
    payload: IReportedBugInfos;
    type: TimelineActionTypes.REPORT_BUG;
}

export interface ISetHeightAction {
    payload: number;
    type: TimelineActionTypes.SET_HEIGHT;
}

export interface ISetWipAction {
    payload: boolean;
    type: TimelineActionTypes.WIP;
}

export interface IShowAction {
    type: TimelineActionTypes.SHOW;
}

export interface IUpdateCriteriasAction {
    payload: ITimelineCriteriasState;
    type: TimelineActionTypes.UPDATE_CRITERIAS;
}

export interface ISetAudienceModeAction {
    payload: string;
    type: TimelineActionTypes.SET_AUDIENCE_MODE;
}

export interface ISetAudienceVOSDALAction {
    payload: boolean;
    type: TimelineActionTypes.SET_AUDIENCE_VOSDAL;
}

export interface ISetCalendarDurationAction {
    payload: number;
    type: TimelineActionTypes.SET_CALENDAR_DURATION;
}

export interface ISetCalendarStartAction {
    payload: Date;
    type: TimelineActionTypes.SET_CALENDAR_START;
}

export interface ISetCalendarStartWarningAction {
    payload: string;
    type: TimelineActionTypes.SET_CALENDAR_START_WARNING;
}

export interface ISetPlaybackSpeedAction {
    payload: number;
    type: TimelineActionTypes.SET_PLAYBACK_SPEED;
}
export interface ISetReportBugErrorsAction {
    payload: Map<string, string>;
    type: TimelineActionTypes.SET_REPORT_BUG_ERRORS;
}

export interface ISetReportingAction {
    payload: boolean;
    type: TimelineActionTypes.SET_REPORTING;
}

export interface ISetStorageBugSeenAction {
    type: TimelineActionTypes.SET_STORAGE_BUG_SEEN;
}

export interface IShowReportBugAction {
    type: TimelineActionTypes.SHOW_REPORT_BUG;
}

export interface IShowStorageBugAction {
    type: TimelineActionTypes.SHOW_STORAGE_BUG;
}

export interface IUpdateAction {
    type: TimelineActionTypes.UPDATE;
}

export interface IZoomInAction {
    type: TimelineActionTypes.ZOOM_IN;
}

export interface IZoomOutAction {
    type: TimelineActionTypes.ZOOM_OUT;
}
