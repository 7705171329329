import { createStyles } from '@material-ui/core';
import { getTheme } from '../../Themes';

const theme = getTheme();

export default createStyles({
    drawer: {
        '&.hidden': {
            width: '0px',
        },
        flexShrink: 0,
    },
    drawerPaper: {
        width: theme.mediaarchiver.dimensions.drawerWidth,
    },
    toolbar: {
        ...theme.mixins.toolbar,
        marginBottom: '12px',
        minHeight: theme.mediaarchiver.dimensions.appBarHeight + '!important',
    },
});
