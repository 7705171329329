import * as FA from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as MD from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { buildBackendURL } from '../../../config';
import { mediaarchiver } from '../../../Protos/protos';
import { IConnectedReduxProps } from '../../../Store';
import { deleteAnnotation, exportAnnotations, openEditDialog } from '../../../Store/Annotations';
import {
    get as getAnnotationTypes,
    IAnnotationTypesState,
    NullableAnnotationType,
} from '../../../Store/AnnotationTypes';
import { showDialog as showExtractionDialog } from '../../../Store/Archives/Actions';
import { I18N, ILocaleInfos } from '../../../Store/I18n';
import {
    IPlayerState,
    setEndSelection,
    setPosition as setPlayerPosition,
    setSnapshot,
    setStartSelection,
} from '../../../Store/Player';
import { ITalkState, setCopiedTalks, showCopyDialog } from '../../../Store/Talk';
import { IEPGProgramState, IEPGState, ITimelineState } from '../../../Store/Timeline';
import { IUserState } from '../../../Store/User';
import { IXMLState } from '../../../Store/XML';
import {
    clearList,
    loadFile as loadXMLFile,
    loadFileStorage as loadXMLFileStorage,
    setCurrentFile as setCurrentXMLFile,
    setError as setXMLError,
} from '../../../Store/XML/Actions';
import {
    getDeclarativeCategoryColor,
    getReadjustedCategoryColor,
    getResultExtractCategoryColor,
    getResultProcessedCategoryColor,
} from '../../../Themes';
import { Logger } from '../../../Utils/Logger';
import { limitText } from '../../../Utils/String';
import { getDateInTz } from '../../../Utils/Time';
import {
    isNull,
    isNullOrUndefined,
    isNullOrUndefinedOrEmptyArray,
    isNullOrUndefinedOrEmptyString,
    isNullOrUndefinedOrZero,
} from '../../../Utils/Various';
import { CopyTalkDialog } from './CopyTalkDialog';

import styles from './style';

import DefaultImage from '../../../Images/icon-no-image.svg';
import { ISearchSTTState, NullableSpeechResult } from '../../../Store/SearchSTT';
import {
    searchSpeechesDate as doSearchByDate,
    setCurrentbegin,
    setCurrentend,
    setCurrentmediaid,
    setCurrentmedianame,
    setCurrentsentence,
    setCurrentstart,
    setCurrenttimestampedwords,
    setCurrentwords,
    setCurrentworduid,
    setNewwords,
    setRequestedCurrent,
    setRequestedEnd,
    setRequestedMedia,
    setRequestedStart,
    setTimelineProgram,
} from '../../../Store/SearchSTT/Actions';
import * as Proto from '../../../Protos/protos';
import { IPlayer } from '../../../Player/Player';
import { clear as clearSTTSearch, searchSpeeches as doSTTSearchSpeeches } from '../../../Store/SearchSTT/Actions';

export interface IUIProps {
    annotationCategoryFilter: string;
    annotationTextFilter: string;
    backgroundCategoryFilter: string;
    backgroundTextFilter: string;
    backgroundstgCategoryFilter: string;
    backgroundstgTextFilter: string;
    arppCategoryFilter: string;
    arppTextFilter: string;
    mnmxbCategoryFilter: string;
    mnmxbTextFilter: string;
    mnionlyCategoryFilter: string;
    mnionlyTextFilter: string;
    sponsoringCategoryFilter: string;
    sponsoringTextFilter: string;
    broadcasterCategoryFilter: string;
    broadcasterTextFilter: string;
    contextualMenuProgram: IEPGProgramState | null;
    crossbucketCategoryFilter: string;
    crossbucketTextFilter: string;
    crossbucketstgCategoryFilter: string;
    crossbucketstgTextFilter: string;
    declarativeCategoryFilter: string;
    declarativeTextFilter: string;
    declarativeRadioCategoryFilter: string;
    declarativeRadioTextFilter: string;
    brutPhonosenseCategoryFilter: string;
    brutPhonosenseTextFilter: string;
    processedResultCategoryFilter: string;
    processedResultTextFilter: string;
    leonV1CategoryFilter: string;
    leonV1TextFilter: string;
    mnmRawCategoryFilter: string;
    mnmRawTextFilter: string;
    mnmProcessedCategoryFilter: string;
    mnmProcessedTextFilter: string;
    extractResultCategoryFilter: string;
    extractResultTextFilter: string;
    ocrCategoryFilter: string;
    ocrTextFilter: string;
    ocrTitleCategoryFilter: string;
    ocrTitleTextFilter: string;
    musicCategoryFilter: string;
    musicTextFilter: string;
    politicalStudyTypeFilter: string;
    politicalTalkTypeFilter: string;
    politicalTimeTypeFilter: string;
    readjustedCategoryFilter: string;
    readjustedTextFilter: string;
    sttTextSearch: string;
    tabValue: number;
    tabSTTValue: number;
    xmlTextFilter: string;
}

interface IPropsFromState {
    annotationTypes: IAnnotationTypesState;
    i18n: I18N;
    localeInfos: ILocaleInfos;
    player: IPlayerState;
    searchSTT: ISearchSTTState;
    timeline: ITimelineState;
    talk: ITalkState;
    user: IUserState;
    xml: IXMLState;
}

interface IPropsFromDispatch {
    clearList: typeof clearList;
    clearSTTSearch: typeof clearSTTSearch;
    deleteAnnotation: typeof deleteAnnotation;
    doSearchByDate: typeof doSearchByDate;
    doSTTSearchSpeeches: typeof doSTTSearchSpeeches;
    exportAnnotations: typeof exportAnnotations;
    getAnnotationTypes: typeof getAnnotationTypes;
    loadXMLFile: typeof loadXMLFile;
    loadXMLFileStorage: typeof loadXMLFileStorage;
    openEditDialog: typeof openEditDialog;
    setCopiedTalks: typeof setCopiedTalks;
    setCurrentXMLFile: typeof setCurrentXMLFile;
    setEndSelection: typeof setEndSelection;
    setPlayerPosition: typeof setPlayerPosition;
    setSnapshot: typeof setSnapshot;
    setStartSelection: typeof setStartSelection;
    setXMLError: typeof setXMLError;
    showCopyDialog: typeof showCopyDialog;
    showExtractionDialog: typeof showExtractionDialog;
    setCurrentbegin: typeof setCurrentbegin;
    setCurrentend: typeof setCurrentend;
    setCurrentmediaid: typeof setCurrentmediaid;
    setCurrentmedianame: typeof setCurrentmedianame;
    setCurrentsentence: typeof setCurrentsentence;
    setCurrentstart: typeof setCurrentstart;
    setCurrenttimestampedwords: typeof setCurrenttimestampedwords;
    setCurrentwords: typeof setCurrentwords;
    setCurrentworduid: typeof setCurrentworduid;
    setNewwords: typeof setNewwords;
    setRequestedCurrent: typeof setRequestedCurrent;
    setRequestedEnd: typeof setRequestedEnd;
    setRequestedMedia: typeof setRequestedMedia;
    setRequestedStart: typeof setRequestedStart;
    setTimelineProgram: typeof setTimelineProgram;
}

type AllProps = MD.WithStyles<typeof styles> &
    IPropsFromState &
    IPropsFromDispatch &
    RouteComponentProps<{}> &
    IConnectedReduxProps;

export default class TimelineAnnotationsComponent extends React.Component<AllProps> {
    private contextualMenu: HTMLDivElement = document.createElement('div');
    public state: IUIProps = {
        annotationCategoryFilter: '',
        annotationTextFilter: '',
        backgroundCategoryFilter: '',
        backgroundTextFilter: '',
        backgroundstgCategoryFilter: '',
        backgroundstgTextFilter: '',
        arppCategoryFilter: '',
        arppTextFilter: '',
        mnmxbCategoryFilter: '',
        mnmxbTextFilter: '',
        mnionlyCategoryFilter: '',
        mnionlyTextFilter: '',
        sponsoringCategoryFilter: '',
        sponsoringTextFilter: '',
        broadcasterCategoryFilter: '',
        broadcasterTextFilter: '',
        contextualMenuProgram: null,
        crossbucketCategoryFilter: '',
        crossbucketTextFilter: '',
        crossbucketstgCategoryFilter: '',
        crossbucketstgTextFilter: '',
        declarativeCategoryFilter: '',
        declarativeRadioCategoryFilter: '',
        declarativeRadioTextFilter: '',
        declarativeTextFilter: '',
        extractResultCategoryFilter: '',
        extractResultTextFilter: '',
        musicCategoryFilter: '',
        musicTextFilter: '',
        politicalStudyTypeFilter: '',
        politicalTalkTypeFilter: '',
        politicalTimeTypeFilter: '',
        processedResultCategoryFilter: '',
        processedResultTextFilter: '',
        leonV1CategoryFilter: '',
        leonV1TextFilter: '',
        mnmRawCategoryFilter: '',
        mnmRawTextFilter: '',
        ocrCategoryFilter: '',
        ocrTextFilter: '',
        ocrTitleCategoryFilter: '',
        ocrTitleTextFilter: '',
        mnmProcessedCategoryFilter: '',
        mnmProcessedTextFilter: '',
        brutPhonosenseCategoryFilter: '',
        brutPhonosenseTextFilter: '',
        readjustedCategoryFilter: '',
        readjustedTextFilter: '',
        sttTextSearch: '',
        tabSTTValue: 0,
        tabValue: 0,
        xmlTextFilter: '',
    };

    private requestAsked = true;
    private requestTime = new Date(0);
    private xmlTimer = -1;
    private lastCriteriasHash = '';
    private updatePositionBound: (this: HTMLElement, ev: any) => any;

    public constructor(props: AllProps) {
        super(props);
        this.updatePositionBound = (ev: CustomEvent) => {
            this.setCursorPosition(ev.detail as Date);
        };
    }

    public componentDidMount(): void {
        this.props.getAnnotationTypes(0, 1000);
        this.props.player.positionUpdateEventElem.addEventListener<any>('timeUpdate', this.updatePositionBound);
        this.props.loadXMLFileStorage();
    }

    public componentWillUnmount(): void {
        this.props.player.positionUpdateEventElem.removeEventListener<any>('timeUpdate', this.updatePositionBound);
    }

    /*
    public componentDidUpdate(): void {
        
    }
*/
    public render(): React.ReactNode {
        if (this.lastCriteriasHash === '' || this.lastCriteriasHash !== this.props.timeline.criteriasHash) {
            this.handleAnnotationReset();
            this.handleBackgroundReset();
            this.handleARPPReset();
            this.handleMNMXBReset();
            this.handleMNIONLYReset();
            this.handleSponsoringReset();
            this.handleBackgroundSTGReset();
            this.handleBroadcasterReset();
            this.handleDeclarativeReset();
            this.handleExtractResultReset();
            this.handleProcessedResultReset();
            this.handleLeonV1Reset();
            this.handleBrutPhonosenseReset();
            this.handleDeclarativeRadioReset();
            this.handleMusicReset();
            this.handlePoliticalReset();
            this.handleReadjustedReset();
            this.handleXMLReset();
            this.lastCriteriasHash = this.props.timeline.criteriasHash;
        }
        return (
            <div className={this.props.classes.root}>
                <Route component={CopyTalkDialog} />
                <MD.AppBar
                    className={this.props.classes.tabsSwitcherContainer}
                    color='default'
                    position='static'
                    key='AppBar_Annotations_Timeline'
                >
                    <MD.Tabs
                        key='Tabs_AppBar_Annotations_Timeline'
                        className={this.props.classes.tabsSwitcher}
                        indicatorColor='primary'
                        onChange={this.handleTabChange.bind(this)}
                        scrollButtons='auto'
                        textColor='primary'
                        value={this.state.tabValue}
                        variant='scrollable'
                    >
                        {this.getTabs()}
                    </MD.Tabs>
                </MD.AppBar>
                <SwipeableViews axis='x' className={this.props.classes.tabsContainer} index={this.state.tabValue}>
                    {this.getTabContents()}
                </SwipeableViews>
            </div>
        );
    }

    private getTabs(): React.ReactNode[] {
        const tabs: React.ReactNode[] = [];

        if (
            Object.keys(this.props.timeline.data.readjustedEPG).length !== 0 /*&&
            Object.keys(this.props.timeline.data.readjustedEPGDetails).length !== 0*/
        ) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_1'
                    label={this.props.i18n._('EPG(R)') + ` [${this.getReadjustedEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.broadcasterEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_2'
                    label={this.props.i18n._('EPG(B)') + ` [${this.getBroadcasterEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.declarativeEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_3'
                    label={this.props.i18n._('EPG(D T)') + ` [${this.getDeclarativeEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.declarativeRadioEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_4'
                    label={this.props.i18n._('EPG(D R)') + ` [${this.getDeclarativeRadioEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.musicEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_5'
                    label={this.props.i18n._('Music') + ` [${this.getMusicEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.backgroundEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_6'
                    label={this.props.i18n._('Background') + ` [${this.getBackgroundEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.backgroundstgEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_7'
                    label={
                        this.props.i18n._('Background es STG') + ` [${this.getBackgroundSTGEPGOrdered(true).length}]`
                    }
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.arppEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_8'
                    label={this.props.i18n._('Spots Publicitaires') + ` [${this.getARPPEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.mnmxbEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_9'
                    label={
                        this.props.i18n._('Relevé musicaux avec musiques non identifiées') +
                        ` [${this.getMNMXBEPGOrdered(true).length}]`
                    }
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.mnionlyEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_9'
                    label={
                        this.props.i18n._('Musiques non identifiées sans relevé musicaux') +
                        ` [${this.getMNIONLYEPGOrdered(true).length}]`
                    }
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.sponsoringEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_10'
                    label={this.props.i18n._('Sponsoring') + ` [${this.getSponsoringEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.talkEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_11'
                    label={this.props.i18n._('Talk') + ` [${this.getTalkEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.annotationsEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_12'
                    label={this.props.i18n._('Annotations') + ` [${this.getAnnotationEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.extractResultEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_13'
                    label={this.props.i18n._('Archives') + ` [${this.getExtractResultEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.processedResultEPG0).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_14'
                    label={this.props.i18n._('Post Nikita') + ` [${this.getProcessedResultEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.leonV1EPG0).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_15'
                    label={this.props.i18n._('Leon V1') + ` [${this.getLeonV1EPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.brutPhonosenseEPG0).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_16'
                    label={this.props.i18n._('Brut reco') + ` [${this.getBrutPhonosenseEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.crossbucketEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_17'
                    label={this.props.i18n._('Relevé musicaux') + ` [${this.getCrossBucketEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.crossbucketstgEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_18'
                    label={this.props.i18n._('CrossBucke stg') + ` [${this.getCrossBucketstgEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.mnmRawEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_19'
                    label={this.props.i18n._('MPM Brut Phonosense') + ` [${this.getMNMRawEPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.mnmProcessedEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_20'
                    label={
                        this.props.i18n._('Total musique détectée') +
                        ` [${this.getMNMProcessedEPGOrdered(true).length}]`
                    }
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.ocrEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_21'
                    label={this.props.i18n._('OCR') + ` [${this.getOCREPGOrdered(true).length}]`}
                />,
            );
        }
        if (Object.keys(this.props.timeline.data.ocrTitleEPG).length !== 0) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_22'
                    label={this.props.i18n._('OCR Title') + ` [${this.getOCRTitleEPGOrdered(true).length}]`}
                />,
            );
        }

        if (this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.STT) !== -1) {
            tabs.push(
                <MD.Tab
                    className={this.props.classes.tabsSwitcherTab}
                    key='annotationTab_70'
                    label={this.props.i18n._('STT')}
                />,
            );
        }

        // always last
        tabs.push(
            <MD.Tab
                className={this.props.classes.tabsSwitcherTab}
                key='annotationTab_71'
                label={this.props.i18n._('XML')}
            />,
        );

        return tabs;
    }

    private getTabContents(): React.ReactNode[] {
        const contents: React.ReactNode[] = [];

        if (
            Object.keys(this.props.timeline.data.readjustedEPG).length !== 0 /*&&
            Object.keys(this.props.timeline.data.readjustedEPGDetails).length !== 0*/
        ) {
            contents.push(this.renderReadjusted());
        }
        if (Object.keys(this.props.timeline.data.broadcasterEPG).length !== 0) {
            contents.push(this.renderBroadcaster());
        }
        if (Object.keys(this.props.timeline.data.declarativeEPG).length !== 0) {
            contents.push(this.renderDeclarative());
        }
        if (Object.keys(this.props.timeline.data.declarativeRadioEPG).length !== 0) {
            contents.push(this.renderDeclarativeRadio());
        }
        if (Object.keys(this.props.timeline.data.musicEPG).length !== 0) {
            contents.push(this.renderMusic());
        }
        if (Object.keys(this.props.timeline.data.backgroundEPG).length !== 0) {
            contents.push(this.renderBackgrounds());
        }
        if (Object.keys(this.props.timeline.data.backgroundstgEPG).length !== 0) {
            contents.push(this.renderBackgroundsSTG());
        }
        if (Object.keys(this.props.timeline.data.arppEPG).length !== 0) {
            contents.push(this.renderARPP());
        }
        if (Object.keys(this.props.timeline.data.mnmxbEPG).length !== 0) {
            contents.push(this.renderMNMXB());
        }
        if (Object.keys(this.props.timeline.data.mnionlyEPG).length !== 0) {
            contents.push(this.renderMNIONLY());
        }
        if (Object.keys(this.props.timeline.data.sponsoringEPG).length !== 0) {
            contents.push(this.renderSponsoring());
        }
        if (Object.keys(this.props.timeline.data.talkEPG).length !== 0) {
            contents.push(this.renderTalks());
        }
        if (Object.keys(this.props.timeline.data.annotationsEPG).length !== 0) {
            contents.push(this.renderAnnotations());
        }
        if (Object.keys(this.props.timeline.data.extractResultEPG).length !== 0) {
            contents.push(this.renderExtractResult());
        }
        if (Object.keys(this.props.timeline.data.processedResultEPG0).length !== 0) {
            contents.push(this.renderProcessedResult());
        }
        if (Object.keys(this.props.timeline.data.leonV1EPG0).length !== 0) {
            contents.push(this.renderLeonV1());
        }
        if (Object.keys(this.props.timeline.data.brutPhonosenseEPG0).length !== 0) {
            contents.push(this.renderBrutPhonosense());
        }
        if (Object.keys(this.props.timeline.data.crossbucketEPG).length !== 0) {
            contents.push(this.renderCrossBucket());
        }
        if (Object.keys(this.props.timeline.data.crossbucketstgEPG).length !== 0) {
            contents.push(this.renderCrossBucketstg());
        }
        if (Object.keys(this.props.timeline.data.mnmRawEPG).length !== 0) {
            contents.push(this.renderMNMRaw());
        }
        if (Object.keys(this.props.timeline.data.mnmProcessedEPG).length !== 0) {
            contents.push(this.renderMNMProcessed());
        }
        if (Object.keys(this.props.timeline.data.ocrEPG).length !== 0) {
            contents.push(this.renderOCR());
        }
        if (Object.keys(this.props.timeline.data.ocrTitleEPG).length !== 0) {
            contents.push(this.renderOCRTitle());
        }

        if (this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.STT) !== -1) {
            contents.push(this.renderSTT());
        }
        contents.push(this.renderXML());
        return contents;
    }

    private renderReadjusted(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentReadjusted'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='readjustedCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'readjustedCategoryFilter',
                            }}
                            onChange={this.handleReadjustedCategoryChange.bind(this)}
                            value={this.state.readjustedCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getReadjustedCategories().map((category, i) => (
                                <MD.MenuItem key={`filterReadjusted_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='readjustedTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleReadjustedReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'readjustedTextFilter'}
                            onChange={this.handleReadjustedTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getReadjustedEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
                            try {
                                category = (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ');
                            } catch (err) {
                                Logger.warn(err as string);
                            }
                        }
                        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.idShowKind)) {
                            categoryCode = epg.metas.idShowKind;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationReadjustedItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={
                                                epg.metas && epg.metas.readjustedLevel === '2'
                                                    ? this.props.classes.readjustedItemHeaderLevel2
                                                    : this.props.classes.readjustedItemHeaderLevel1
                                            }
                                            style={{
                                                backgroundColor: getReadjustedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }
    private renderBroadcaster(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentBroadcaster'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='broadcasterCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'broadcasterCategoryFilter',
                            }}
                            onChange={this.handleBroadcasterCategoryChange.bind(this)}
                            value={this.state.broadcasterCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getBroadcasterCategories().map((category, i) => (
                                <MD.MenuItem key={`filterBroadcaster_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='broadcasterTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleBroadcasterReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'broadcasterTextFilter'}
                            onChange={this.handleBroadcasterTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getBroadcasterEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
                            try {
                                category = (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ');
                            } catch (err) {
                                Logger.warn(err as string);
                            }
                        }
                        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.idShowKind)) {
                            categoryCode = epg.metas.idShowKind;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationBroadcasterItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={
                                                epg.metas && epg.metas.broadcasterLevel === '2'
                                                    ? this.props.classes.readjustedItemHeaderLevel2
                                                    : this.props.classes.readjustedItemHeaderLevel1
                                            }
                                            style={{
                                                backgroundColor: getReadjustedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }
    private renderDeclarative(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentDeclarative'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='declarativeCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'declarativeCategoryFilter',
                            }}
                            onChange={this.handleDeclarativeCategoryChange.bind(this)}
                            value={this.state.declarativeCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getDeclarativeCategories().map((category, i) => (
                                <MD.MenuItem key={`filterDeclarative_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='declarativeTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleDeclarativeReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'declarativeTextFilter'}
                            onChange={this.handleDeclarativeTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getDeclarativeEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationDeclarativeItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div
                                            className={this.props.classes.annotationItemHeaderLevel}
                                            style={{
                                                backgroundColor: getDeclarativeCategoryColor(
                                                    epg.category.toLocaleLowerCase(),
                                                ),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(this.getDeclarativeCategory(epg).toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderDeclarativeRadio(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentDeclarativeRadio'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='declarativeRadioCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'declarativeRadioCategoryFilter',
                            }}
                            onChange={this.handleDeclarativeRadioCategoryChange.bind(this)}
                            value={this.state.declarativeRadioCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getDeclarativeRadioCategories().map((category, i) => (
                                <MD.MenuItem key={`filterDeclarativeRadio_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='declarativeRadioTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleDeclarativeRadioReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'declarativeRadioTextFilter'}
                            onChange={this.handleDeclarativeRadioTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getDeclarativeRadioEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
                            try {
                                category = (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ');
                            } catch (err) {
                                Logger.warn(err as string);
                            }
                        }
                        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.idShowKind)) {
                            categoryCode = epg.metas.idShowKind;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationDeclarativeRadioItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={
                                                epg.metas && epg.metas.declarativeRadioLevel === '2'
                                                    ? this.props.classes.readjustedItemHeaderLevel2
                                                    : this.props.classes.readjustedItemHeaderLevel1
                                            }
                                            style={{
                                                backgroundColor: getReadjustedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderBrutPhonosense(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentBrutPhonosense'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='brutPhonosenseCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'brutPhonosenseCategoryFilter',
                            }}
                            onChange={this.handleBrutPhonosenseCategoryChange.bind(this)}
                            value={this.state.brutPhonosenseCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getBrutPhonosenseCategories().map((category, i) => (
                                <MD.MenuItem key={`filterBrutPhonosense_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='brutPhonosenseTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleBrutPhonosenseReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'brutPhonosenseTextFilter'}
                            onChange={this.handleBrutPhonosenseTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getBrutPhonosenseEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefinedOrEmptyString(epg.category)) {
                            category = epg.category + ' - ' + epg.categoryText;
                        }
                        if (!isNullOrUndefined(epg.categoryText)) {
                            categoryCode = epg.categoryText;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationBrutPhonosenseItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={
                                                epg.metas && epg.metas.brutPhonosenseLevel === '2'
                                                    ? this.props.classes.readjustedItemHeaderLevel2
                                                    : this.props.classes.readjustedItemHeaderLevel1
                                            }
                                            style={{
                                                backgroundColor: getReadjustedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderProcessedResult(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentProcessedResult'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='processedResultCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'processedResultCategoryFilter',
                            }}
                            onChange={this.handleProcessedResultCategoryChange.bind(this)}
                            value={this.state.processedResultCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getProcessedResultCategories().map((category, i) => (
                                <MD.MenuItem key={`filterProcessedResult_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='processedResultTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleProcessedResultReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'processedResultTextFilter'}
                            onChange={this.handleProcessedResultTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getProcessedResultEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefinedOrEmptyString(epg.category)) {
                            category = epg.category + ' - ' + epg.categoryText;
                        }
                        if (!isNullOrUndefined(epg.category)) {
                            categoryCode = epg.category;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationProcessedResultItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={this.props.classes.readjustedItemHeaderLevel1}
                                            style={{
                                                backgroundColor: getResultProcessedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderLeonV1(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentLeonV1'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='leonV1CategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'leonV1CategoryFilter',
                            }}
                            onChange={this.handleLeonV1CategoryChange.bind(this)}
                            value={this.state.leonV1CategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getLeonV1Categories().map((category, i) => (
                                <MD.MenuItem key={`filterLeonV1_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='leonV1TextFilter'>{this.props.i18n._('Filter by title')}</MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleLeonV1Reset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'leonV1TextFilter'}
                            onChange={this.handleLeonV1TextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getLeonV1EPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefinedOrEmptyString(epg.category)) {
                            category = epg.category + ' - ' + epg.categoryText;
                        }
                        if (!isNullOrUndefined(epg.category)) {
                            categoryCode = epg.category;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationLeonV1Item_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={this.props.classes.readjustedItemHeaderLevel1}
                                            style={{
                                                backgroundColor: getResultProcessedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderMNMRaw(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentMNMRaw'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='mnmRawCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'mnmRawCategoryFilter',
                            }}
                            onChange={this.handleMNMRawCategoryChange.bind(this)}
                            value={this.state.mnmRawCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getMNMRawCategories().map((category, i) => (
                                <MD.MenuItem key={`filterMNM_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='mnmRawTextFilter'>{this.props.i18n._('Filter by title')}</MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleMNMRawReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'mnmRawTextFilter'}
                            onChange={this.handleMNMRawTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getMNMRawEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefinedOrEmptyString(epg.category)) {
                            category = epg.category + ' - ' + epg.categoryText;
                        }
                        if (!isNullOrUndefined(epg.category)) {
                            categoryCode = epg.category;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationMNMRawItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={this.props.classes.readjustedItemHeaderLevel1}
                                            style={{
                                                backgroundColor: getResultProcessedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderMNMProcessed(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentMNMProcessed'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='mnmProcessedCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'mnmProcessedCategoryFilter',
                            }}
                            onChange={this.handleMNMProcessedCategoryChange.bind(this)}
                            value={this.state.mnmProcessedCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getMNMProcessedCategories().map((category, i) => (
                                <MD.MenuItem key={`filterMNM_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='mnmProcessedTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleMNMProcessedReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'mnmProcessedTextFilter'}
                            onChange={this.handleMNMProcessedTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getMNMProcessedEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefinedOrEmptyString(epg.category)) {
                            category = epg.category + ' - ' + epg.categoryText;
                        }
                        if (!isNullOrUndefined(epg.category)) {
                            categoryCode = epg.category;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationMNMProcessedItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={this.props.classes.readjustedItemHeaderLevel1}
                                            style={{
                                                backgroundColor: getResultProcessedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderExtractResult(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentExtractResult'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='extractResultCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'extractResultCategoryFilter',
                            }}
                            onChange={this.handleExtractResultCategoryChange.bind(this)}
                            value={this.state.extractResultCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getExtractResultCategories().map((category, i) => (
                                <MD.MenuItem key={`filterExtractResult_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='extractResultTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleExtractResultReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'extractResultTextFilter'}
                            onChange={this.handleExtractResultTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getExtractResultEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefinedOrEmptyString(epg.category)) {
                            category = epg.category + ' - ' + epg.categoryText;
                        }
                        if (!isNullOrUndefined(epg.category)) {
                            categoryCode = epg.category;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationExtractResultItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={this.props.classes.readjustedItemHeaderLevel1}
                                            style={{
                                                backgroundColor: getResultExtractCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart).format('LTS')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderMusic(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentMusic'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='musicCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'musicCategoryFilter',
                            }}
                            onChange={this.handleMusicCategoryChange.bind(this)}
                            value={this.state.musicCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getMusicCategories().map((category, i) => (
                                <MD.MenuItem key={`filterMusic_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='musicTextFilter'>{this.props.i18n._('Filter by title')}</MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleMusicReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'musicTextFilter'}
                            onChange={this.handleMusicTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getMusicEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let artist = this.props.i18n._('Unknown artist');
                        let imgSrc = DefaultImage;

                        if (
                            !isNullOrUndefinedOrEmptyString(epg.metas) &&
                            !isNullOrUndefinedOrEmptyString(epg.metas.trackID)
                        ) {
                            imgSrc = buildBackendURL(`/cover/227-3-175-171-176-177-173/${epg.metas.trackID}`);
                        }
                        if (
                            !isNullOrUndefinedOrEmptyString(epg.metas) &&
                            !isNullOrUndefinedOrEmptyString(epg.metas.artist)
                        ) {
                            artist = epg.metas.artist;
                        }
                        if (artist.length > 50) {
                            artist = artist.substr(0, 46) + ' ...';
                        }
                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationMusicItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div
                                            className={this.props.classes.annotationItemHeaderPicture}
                                            style={{
                                                backgroundImage: `url('${imgSrc}')`,
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(artist, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderBackgrounds(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentBackgrounds'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='backgroundCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'backgroundCategoryFilter',
                            }}
                            onChange={this.handleBackgroundCategoryChange.bind(this)}
                            value={this.state.backgroundCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getBackgroundCategories().map((category, i) => (
                                <MD.MenuItem key={`filterBackground_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='backgroundTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleBackgroundReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'backgroundTextFilter'}
                            onChange={this.handleBackgroundTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getBackgroundEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationBackgroundItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                            <br />
                                            {moment(new Date(epg.realStart.getTime() + epg.duration))
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderBackgroundsSTG(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentBackgroundsSTG'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='backgroundstgCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'backgroundstgCategoryFilter',
                            }}
                            onChange={this.handleBackgroundSTGCategoryChange.bind(this)}
                            value={this.state.backgroundCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getBackgroundSTGCategories().map((category, i) => (
                                <MD.MenuItem key={`filterBackground_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='backgroundstgTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleBackgroundSTGReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'backgroundstgTextFilter'}
                            onChange={this.handleBackgroundSTGTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getBackgroundSTGEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationBackgroundItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                            <br />
                                            {moment(new Date(epg.realStart.getTime() + epg.duration))
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderARPP(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentARPP'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='arppCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'arppCategoryFilter',
                            }}
                            onChange={this.handleARPPCategoryChange.bind(this)}
                            value={this.state.arppCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getARPPCategories().map((category, i) => (
                                <MD.MenuItem key={`filterARPP_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='arppTextFilter'>{this.props.i18n._('Filter by title')}</MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleARPPReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'arppTextFilter'}
                            onChange={this.handleARPPTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getARPPEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationARPPItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderMNMXB(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentMNMXB'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='mnmxbCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'mnmxbCategoryFilter',
                            }}
                            onChange={this.handleMNMXBCategoryChange.bind(this)}
                            value={this.state.mnmxbCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getMNMXBCategories().map((category, i) => (
                                <MD.MenuItem key={`filterMNMXB_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='mnmxbTextFilter'>{this.props.i18n._('Filter by title')}</MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleMNMXBReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'mnmxbTextFilter'}
                            onChange={this.handleMNMXBTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getMNMXBEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationMNMXBItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderMNIONLY(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentMNIONLY'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='mnionlyCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'mnionlyCategoryFilter',
                            }}
                            onChange={this.handleMNIONLYCategoryChange.bind(this)}
                            value={this.state.mnionlyCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getMNIONLYCategories().map((category, i) => (
                                <MD.MenuItem key={`filterMNIONLY_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='mnionlyTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleMNIONLYReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'mnionlyTextFilter'}
                            onChange={this.handleMNIONLYTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getMNIONLYEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationMNIONLYItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderSponsoring(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentSponsoring'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='sponsoringCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'sponsoringCategoryFilter',
                            }}
                            onChange={this.handleSponsoringCategoryChange.bind(this)}
                            value={this.state.sponsoringCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getSponsoringCategories().map((category, i) => (
                                <MD.MenuItem key={`filterSponsoring_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='sponsoringTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleSponsoringReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'sponsoringTextFilter'}
                            onChange={this.handleSponsoringTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getSponsoringEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationSponsoringItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderCrossBucket(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentCrossBucket'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='crossbucketCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'crossbucketCategoryFilter',
                            }}
                            onChange={this.handleCrossBucketCategoryChange.bind(this)}
                            value={this.state.crossbucketCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getCrossBucketCategories().map((category, i) => (
                                <MD.MenuItem key={`filterCrossBucket_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='crossbucketTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleCrossBucketReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'crossbucketTextFilter'}
                            onChange={this.handleCrossBucketTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getCrossBucketEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationCrossBucketItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderCrossBucketstg(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentCrossBucketstg'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='crossbucketstgCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'crossbucketstgCategoryFilter',
                            }}
                            onChange={this.handleCrossBucketstgCategoryChange.bind(this)}
                            value={this.state.crossbucketstgCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getCrossBucketstgCategories().map((category, i) => (
                                <MD.MenuItem key={`filterCrossBucketstg_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='crossbucketstgTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleCrossBucketstgReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'crossbucketstgTextFilter'}
                            onChange={this.handleCrossBucketstgTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getCrossBucketstgEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        let bucket = this.props.i18n._('Unknown artist');

                        if (epg.metas && epg.metas.bucket) {
                            bucket = epg.metas.bucket;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationCrossBucketstgItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(bucket, 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderOCR(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentOCR'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='ocrCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'ocrCategoryFilter',
                            }}
                            onChange={this.handleOCRCategoryChange.bind(this)}
                            value={this.state.ocrCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getOCRCategories().map((category, i) => (
                                <MD.MenuItem key={`filterOCR_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='ocrTextFilter'>{this.props.i18n._('Filter by title')}</MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleOCRReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'ocrTextFilter'}
                            onChange={this.handleOCRTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getOCREPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
                            try {
                                category = (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ');
                            } catch (err) {
                                Logger.warn(err as string);
                            }
                        }
                        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.idShowKind)) {
                            categoryCode = epg.metas.idShowKind;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationBroadcasterItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={
                                                epg.metas && epg.metas.ocrLevel === '2'
                                                    ? this.props.classes.readjustedItemHeaderLevel2
                                                    : this.props.classes.readjustedItemHeaderLevel1
                                            }
                                            style={{
                                                backgroundColor: getReadjustedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderOCRTitle(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentOCRTitle'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='ocrTitleCategoryFilter'>
                            {this.props.i18n._('Filter by category')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'ocrCategoryFilter',
                            }}
                            onChange={this.handleOCRTitleCategoryChange.bind(this)}
                            value={this.state.ocrTitleCategoryFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All categories')}</MD.MenuItem>
                            {this.getOCRTitleCategories().map((category, i) => (
                                <MD.MenuItem key={`filterOCRTitle_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='ocrTitleTextFilter'>
                            {this.props.i18n._('Filter by title')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleOCRTitleReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'ocrTitleTextFilter'}
                            onChange={this.handleOCRTitleTextChange.bind(this)}
                        />
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getOCRTitleEPGOrdered().map((epg, i) => {
                        let categoryCode = '';
                        let category = this.props.i18n._('Unknown category');

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        if (!isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
                            try {
                                category = (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ');
                            } catch (err) {
                                Logger.warn(err as string);
                            }
                        }
                        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.idShowKind)) {
                            categoryCode = epg.metas.idShowKind;
                        }
                        let title = epg.title;
                        if (!isNullOrUndefinedOrEmptyString(epg.subtitle)) {
                            title = `${epg.title}: ${epg.subtitle}`;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationBroadcasterItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.readjustedItemHeader}>
                                        <div
                                            className={
                                                epg.metas && epg.metas.ocrTitleLevel === '2'
                                                    ? this.props.classes.readjustedItemHeaderLevel2
                                                    : this.props.classes.readjustedItemHeaderLevel1
                                            }
                                            style={{
                                                backgroundColor: getReadjustedCategoryColor(categoryCode),
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeader}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(title.toLocaleUpperCase(), 100)}
                                    secondary={limitText(category.toLocaleLowerCase(), 100)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderTalks(): React.ReactNode {
        const selectedStarts: number[] = [];

        this.props.talk.copiedTalks.forEach((t) => {
            if (!isNullOrUndefined(t.Speakers)) {
                t.Speakers.forEach((s) => {
                    if (!isNullOrUndefinedOrZero(s.Start) && selectedStarts.indexOf(s.Start as number) === -1) {
                        selectedStarts.push(s.Start as number);
                    }
                });
            }
        });

        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentTalks'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='talkStudyTypeFilter'>
                            {this.props.i18n._('Filter by study stype')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'talkStudyTypeFilter',
                            }}
                            onChange={this.handlePoliticalStudyTypeChange.bind(this)}
                            value={this.state.politicalStudyTypeFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All types')}</MD.MenuItem>
                            <MD.MenuItem value={mediaarchiver.TalkStudyType.TALK_TYPE_ELECTION.toString(10)}>
                                {this.props.i18n.pgettext('Politic', 'Election')}
                            </MD.MenuItem>
                            <MD.MenuItem value={mediaarchiver.TalkStudyType.TALK_TYPE_PLURALISM.toString(10)}>
                                {this.props.i18n.pgettext('Politic', 'Pluralism')}
                            </MD.MenuItem>
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='talkTalkTypeFilter'>
                            {this.props.i18n._('Filter by entry type')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'talkTalkTypeFilter',
                            }}
                            onChange={this.handlePoliticalTalkTypeChange.bind(this)}
                            value={this.state.politicalTalkTypeFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All types')}</MD.MenuItem>
                            <MD.MenuItem value={mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED.toString(10)}>
                                {this.props.i18n.pgettext('Politic', 'Adjusted')}
                            </MD.MenuItem>
                            <MD.MenuItem value={mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED.toString(10)}>
                                {this.props.i18n.pgettext('Politic', 'Aggregated')}
                            </MD.MenuItem>
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='talkTimeTypeFilter'>
                            {this.props.i18n._('Filter by time type')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'talkTimeTypeFilter',
                            }}
                            onChange={this.handlePoliticalTimeTypeChange.bind(this)}
                            value={this.state.politicalTimeTypeFilter}
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All types')}</MD.MenuItem>
                            <MD.MenuItem value={mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK.toString(10)}>
                                {this.props.i18n.pgettext('Politic', 'Speak time')}
                            </MD.MenuItem>
                            <MD.MenuItem value={mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST.toString(10)}>
                                {this.props.i18n.pgettext('Politic', 'Broadcast time')}
                            </MD.MenuItem>
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl>
                        <MD.Button
                            disabled={
                                this.props.talk.copiedTalks.length === 0 ||
                                this.state.politicalStudyTypeFilter.length === 0
                            }
                            onClick={(ev: React.MouseEvent) => {
                                ev.stopPropagation();
                                ev.preventDefault();
                                this.props.showCopyDialog();
                            }}
                            title={this.props.i18n.pgettext('Politic', 'Copy times')}
                        >
                            {this.props.i18n.pgettext('Politic', 'Copy')}
                        </MD.Button>
                    </MD.FormControl>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getTalkEPGOrdered().map((epg, i) => {
                        const start = epg.realStart.getTime();

                        if (isNullOrUndefined(epg)) {
                            return null;
                        }

                        return (
                            <MD.ListItem
                                button={true}
                                className={this.props.classes.annotationItem}
                                key={`annotationTalkItem_${i}`}
                                onClick={this.handleProgramView.bind(this, epg)}
                            >
                                <MD.ListItemIcon>
                                    <div className={this.props.classes.annotationItemHeader}>
                                        <MD.Checkbox
                                            checked={selectedStarts.indexOf(start) !== -1}
                                            onClick={(ev: React.MouseEvent) => {
                                                ev.stopPropagation();
                                                ev.preventDefault();
                                                const talk = this.talkEPGToTalk(epg);
                                                if (selectedStarts.indexOf(start) === -1) {
                                                    this.props.setCopiedTalks([...this.props.talk.copiedTalks, talk]);
                                                } else {
                                                    this.props.setCopiedTalks(
                                                        this.props.talk.copiedTalks.filter((t) => t.ID !== talk.ID),
                                                    );
                                                }
                                                return false;
                                            }}
                                        />
                                        <div className={this.props.classes.annotationItemHeaderText}>
                                            {moment(epg.realStart)
                                                .clone()
                                                .tz(this.props.timeline.criterias.timezone)
                                                .format('HH:mm:ss')}
                                        </div>
                                    </div>
                                </MD.ListItemIcon>
                                <MD.ListItemText
                                    className={this.props.classes.listItemTexts}
                                    primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    secondary={this.getTalkEPG(epg)}
                                />
                                <MD.ListItemSecondaryAction>
                                    <MD.Button
                                        onClick={this.handleProgramView.bind(this, epg)}
                                        title={this.props.i18n._('View content')}
                                    >
                                        <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                    </MD.Button>
                                </MD.ListItemSecondaryAction>
                            </MD.ListItem>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private talkEPGToTalk(epg: IEPGProgramState): mediaarchiver.ITalk {
        let district: number | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.district)) {
            const v = parseInt(epg.metas.district, 10);

            if (!isNaN(v) && v > 0) {
                district = v;
            }
        }

        let owner: number | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.owner)) {
            const v = parseInt(epg.metas.owner, 10);

            if (!isNaN(v) && v > 0) {
                owner = v;
            }
        }

        let programEnd: number | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.program_end)) {
            const v = parseInt(epg.metas.program_end, 10);

            if (!isNaN(v) && v > 0) {
                programEnd = v;
            }
        }

        let programStart: number | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.program_start)) {
            const v = parseInt(epg.metas.program_start, 10);

            if (!isNaN(v) && v > 0) {
                programStart = v;
            }
        }

        let programType: number | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.program_type)) {
            const v = parseInt(epg.metas.program_type, 10);

            if (!isNaN(v) && v > 0) {
                programType = v;
            }
        }

        let study: number | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.studyType)) {
            const v = parseInt(epg.metas.studyType, 10);

            if (!isNaN(v) && v > 0) {
                study = v;
            }
        }

        let talkType: mediaarchiver.TalkTalkType | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.talk_type)) {
            const v = parseInt(epg.metas.talk_type, 10);

            if (!isNaN(v) && v > 0) {
                switch (v) {
                    case mediaarchiver.TalkTalkType.TALK_TALK_TYPE_ADJUSTED:
                    case mediaarchiver.TalkTalkType.TALK_TALK_TYPE_AGGREGATED:
                        talkType = v;
                        break;
                    default:
                        break;
                }
            }
        }

        let timeType: mediaarchiver.TalkTimeType | undefined = undefined;

        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.time_type)) {
            const v = parseInt(epg.metas.time_type, 10);

            if (!isNaN(v) && v > 0) {
                switch (v) {
                    case mediaarchiver.TalkTimeType.TALK_TIME_TYPE_BROADCAST:
                    case mediaarchiver.TalkTimeType.TALK_TIME_TYPE_SPEAK:
                        timeType = v;
                        break;
                    default:
                        break;
                }
            }
        }

        let speakers: mediaarchiver.ITalkSpeaker[] | undefined = undefined;
        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.speakers)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let parsed: any;

            try {
                parsed = JSON.parse(epg.metas.speakers);
            } catch (err) {}

            if (Array.isArray(parsed)) {
                speakers = parsed;
            }
        }

        return {
            Comment: undefined,
            Company: this.props.user.user.group,
            District: district,
            ID:
                !isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas.id)
                    ? epg.metas.id
                    : undefined,
            Media: this.props.timeline.criterias.media,
            Owner: owner,
            OwnerName:
                !isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas.ownerName)
                    ? epg.metas.ownerName
                    : undefined,
            ProgramEnd: programEnd,
            ProgramName:
                !isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas.program_name)
                    ? epg.metas.program_name
                    : undefined,
            ProgramStart: programStart,
            ProgramType: programType,
            Speakers: speakers,
            Study: study,
            TalkType: talkType,
            TimeType: timeType,
        };
    }

    private renderAnnotations(): React.ReactNode {
        return (
            <MD.Typography
                className={this.props.classes.tabsContent}
                component='div'
                key='annotationTabContentAnnotation'
            >
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.InputLabel htmlFor='annotationCategoryFilter'>
                            {this.props.i18n._('Filter by annotation type')}
                        </MD.InputLabel>
                        <MD.Select
                            inputProps={{
                                id: 'annotationCategoryFilter',
                            }}
                            onChange={this.handleAnnotationCategoryChange.bind(this)}
                            value={
                                this.getAnnotationCategories().length === 1
                                    ? this.getAnnotationCategories()[0]
                                    : this.state.annotationCategoryFilter
                            }
                        >
                            <MD.MenuItem value=''>{this.props.i18n._('All types')}</MD.MenuItem>
                            {this.getAnnotationCategories().map((category, i) => (
                                <MD.MenuItem key={`filterAnnotation_${i}`} value={category}>
                                    {category}
                                </MD.MenuItem>
                            ))}
                        </MD.Select>
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='annotationTextFilter'>
                            {this.props.i18n._('Filter by value')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleAnnotationReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'annotationTextFilter'}
                            onChange={this.handleAnnotationTextChange.bind(this)}
                        />
                    </MD.FormControl>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxCategory}
                    >
                        <MD.Button
                            className={this.props.classes.exportButton}
                            disabled={
                                (this.getAnnotationCategories().length === 1
                                    ? this.getAnnotationCategories()[0]
                                    : this.state.annotationCategoryFilter) === ''
                            }
                            onClick={() => {
                                let id = '';
                                const currentChoice =
                                    this.getAnnotationCategories().length === 1
                                        ? this.getAnnotationCategories()[0]
                                        : this.state.annotationCategoryFilter;
                                this.props.annotationTypes.annotationTypes.some((t: NullableAnnotationType) => {
                                    if (
                                        !isNullOrUndefined(t) &&
                                        !isNullOrUndefinedOrEmptyString(t.Name) &&
                                        t.Name === currentChoice
                                    ) {
                                        id = t.ID;
                                        return true;
                                    }
                                    return false;
                                });
                                // export annotations
                                this.props.exportAnnotations({
                                    DateEnd: this.props.timeline.criterias.end.getTime(),
                                    DateStart: this.props.timeline.criterias.start.getTime(),
                                    Medias: [this.props.timeline.criterias.media],
                                    Type: id,
                                });
                            }}
                        >
                            {this.props.i18n._('Export')}
                        </MD.Button>
                    </MD.FormControl>
                </form>
                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.getAnnotationEPGOrdered().map((epg, i) => {
                        if (isNullOrUndefined(epg)) {
                            return null;
                        }
                        const owned =
                            !isNullOrUndefined(this.state.contextualMenuProgram) &&
                            !isNullOrUndefined(this.state.contextualMenuProgram.metas) &&
                            this.state.contextualMenuProgram.metas.owned === 'true';

                        let value = this.getAnnotationValue(epg);

                        if (value.length > 50) {
                            value = value.substr(0, 46) + ' ...';
                        }
                        return (
                            <>
                                <MD.ListItem
                                    button={true}
                                    className={this.props.classes.annotationItem}
                                    key={`annotationAnnotationItem_${i}`}
                                    onClick={this.handleProgramView.bind(this, epg)}
                                    onContextMenu={this.handleContext.bind(this, epg)}
                                >
                                    <MD.ListItemIcon>
                                        <div className={this.props.classes.annotationItemHeader}>
                                            <div className={this.props.classes.annotationItemHeaderText}>
                                                {moment(epg.realStart)
                                                    .clone()
                                                    .tz(this.props.timeline.criterias.timezone)
                                                    .format('HH:mm:ss')}
                                            </div>
                                        </div>
                                    </MD.ListItemIcon>
                                    <MD.ListItemText
                                        className={this.props.classes.listItemTexts}
                                        primary={limitText(this.getAnnotationCategory(epg).toLocaleUpperCase(), 100)}
                                        secondary={limitText(value, 100)}
                                    />
                                    <MD.ListItemSecondaryAction>
                                        <MD.Button
                                            onClick={this.handleProgramView.bind(this, epg)}
                                            title={this.props.i18n._('View content')}
                                        >
                                            <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                        </MD.Button>
                                    </MD.ListItemSecondaryAction>
                                </MD.ListItem>
                                {!isNullOrUndefined(this.state.contextualMenuProgram) ? (
                                    <MD.Menu
                                        anchorEl={this.contextualMenu}
                                        open={true}
                                        onClose={() => {
                                            this.setState({ contextualMenuProgram: null });
                                            this.contextualMenu = document.createElement('div');
                                        }}
                                    >
                                        <MD.MenuItem
                                            button
                                            disabled={!owned}
                                            onClick={this.onEditAnnotation.bind(this)}
                                        >
                                            {this.props.i18n._('Edit annotation')}
                                        </MD.MenuItem>
                                        <MD.MenuItem
                                            button
                                            disabled={!owned}
                                            onClick={this.onDeleteAnnotation.bind(this)}
                                        >
                                            {this.props.i18n._('Delete annotation')}
                                        </MD.MenuItem>
                                        <MD.MenuItem button onClick={this.onArchiveAnnotation.bind(this)}>
                                            {this.props.i18n._('Create an archive')}
                                        </MD.MenuItem>
                                    </MD.Menu>
                                ) : (
                                    ''
                                )}
                            </>
                        );
                    })}
                </MD.List>
            </MD.Typography>
        );
    }

    private renderXML(): React.ReactNode {
        const epgs = this.getXMLEPGOrdered();
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='annotationTabContentXML'>
                <MD.Paper className={this.props.classes.xmlHeader}>
                    <input
                        accept='text/xml'
                        onChange={this.handleXMLFile.bind(this)}
                        style={{ display: 'none' }}
                        id='fileButton'
                        type='file'
                    />
                    <label htmlFor='fileButton'>
                        <MD.Button color='primary' component='span' variant='contained' size='small'>
                            <FontAwesomeIcon icon={FA.faUpload} />
                            &nbsp;
                            {this.props.i18n._('Load file')}
                        </MD.Button>
                    </label>
                    {this.props.xml.files.size > 0 ? (
                        <MD.Select
                            fullWidth={true}
                            inputProps={{
                                id: 'filesList',
                            }}
                            onChange={this.onChangeFile.bind(this)}
                            value={this.props.xml.currentFile}
                        >
                            {Array.from(this.props.xml.files.keys()).map((file: unknown) =>
                                typeof file === 'string' ? (
                                    <MD.MenuItem key={`file_${Math.random()}`} value={file}>
                                        {file}
                                    </MD.MenuItem>
                                ) : (
                                    ''
                                ),
                            )}
                        </MD.Select>
                    ) : (
                        ''
                    )}

                    {this.props.xml.files.size > 0 ? (
                        <>
                            <MD.IconButton
                                aria-label={this.props.i18n._('Clear list')}
                                onClick={this.clearXMLFile.bind(this)}
                            >
                                <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                            </MD.IconButton>
                        </>
                    ) : (
                        ''
                    )}
                </MD.Paper>
                {epgs.length > 0 ? (
                    <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                        <MD.FormControl
                            className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                        >
                            <MD.InputLabel htmlFor='xmlTextFilter'>
                                {this.props.i18n._('Filter by label')}
                            </MD.InputLabel>
                            <MD.Input
                                endAdornment={
                                    <MD.InputAdornment position='end'>
                                        <MD.IconButton
                                            aria-label={this.props.i18n._('Reset filter')}
                                            onClick={this.handleXMLReset.bind(this)}
                                        >
                                            <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                        </MD.IconButton>
                                    </MD.InputAdornment>
                                }
                                id={'xmlTextFilter'}
                                onChange={this.handleXMLTextChange.bind(this)}
                            />
                        </MD.FormControl>
                    </form>
                ) : (
                    ''
                )}
                {epgs.length > 0 ? (
                    <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                        {epgs.map((epg, i) => {
                            if (isNullOrUndefined(epg)) {
                                return null;
                            }

                            return (
                                <MD.ListItem
                                    button={true}
                                    className={this.props.classes.annotationItem}
                                    key={`xmlAnnotationItem_${i}`}
                                    onClick={this.handleProgramView.bind(this, epg)}
                                >
                                    <MD.ListItemIcon>
                                        <div className={this.props.classes.annotationItemHeader}>
                                            <div className={this.props.classes.annotationItemHeaderText}>
                                                {moment(epg.realStart)
                                                    .clone()
                                                    .tz(this.props.timeline.criterias.timezone)
                                                    .format('HH:mm:ss')}
                                            </div>
                                        </div>
                                    </MD.ListItemIcon>
                                    <MD.ListItemText
                                        className={this.props.classes.listItemTexts}
                                        primary={limitText(epg.title.toLocaleUpperCase(), 100)}
                                    />
                                    <MD.ListItemSecondaryAction>
                                        <MD.Button
                                            onClick={this.handleProgramView.bind(this, epg)}
                                            title={this.props.i18n._('View content')}
                                        >
                                            <FontAwesomeIcon icon={FA.faEye} size='sm' />
                                        </MD.Button>
                                    </MD.ListItemSecondaryAction>
                                </MD.ListItem>
                            );
                        })}
                    </MD.List>
                ) : (
                    ''
                )}
            </MD.Typography>
        );
    }

    private setCursorPosition(time: Date) {
        if (this.props.timeline.criterias.contentTypes.indexOf(Proto.mediaarchiver.EPGType.STT) !== -1) {
            const a = document.getElementsByClassName(this.props.classes.highlightedWordText);
            if (time < this.props.searchSTT.requestedEnd && time > this.props.searchSTT.requestedStart) {
                this.highligthPlayerText(time.getTime());
                if (!isNullOrUndefined(a[0])) {
                    a[0].scrollIntoView();
                }
            } else {
                if (this.requestAsked) {
                    this.requestAsked = false;
                    window.setTimeout(() => {
                        this.props.setRequestedEnd(new Date(time.getTime() + 10 * 60 * 1000));
                    }, 50);
                    window.setTimeout(() => {
                        this.props.setRequestedStart(new Date(time.getTime() - 2 * 60 * 1000));
                    }, 50);
                    window.setTimeout(() => {
                        this.props.doSearchByDate({
                            end: new Date(time.getTime() + 10 * 60 * 1000),
                            medias: this.props.timeline.criterias.media,
                            start: new Date(time.getTime() - 2 * 60 * 1000),
                        });
                    }, 50);
                }
            }
        }
    }

    private updateText() {
        this.requestAsked = true;

        const newWords = this.props.searchSTT.newWords;

        const lastWord = newWords[newWords.length - 1];
        const newEnd = lastWord.Dte as number;

        const firstWord = newWords[0];
        const newBegin = firstWord.Dts as number;

        const myObj = new Map<number, string>();
        let previousSpeaker = '';

        const sentence = newWords.map((word: Proto.mediaarchiver.ISearchSpeechesWord, index: number) => {
            if (isNullOrUndefinedOrEmptyString(word.Word)) {
                return '';
            }
            if (isNullOrUndefinedOrEmptyString(word.WordUID)) {
                return '';
            }
            if (isNullOrUndefinedOrZero(word.Dts)) {
                return '';
            }

            myObj.set(word.Dts, word.WordUID + '_player');

            const newSpeaker = word.WordUID.split('_')[word.WordUID.split('_').length - 3];

            if (isNullOrUndefinedOrEmptyString(newSpeaker)) {
                return <p>{word.Word}</p>;
            }

            if (newSpeaker === previousSpeaker || index === 0) {
                previousSpeaker = newSpeaker;
                return (
                    <React.Fragment>
                        {index === 0 ? (
                            <a
                                style={
                                    previousSpeaker[0] === 'M'
                                        ? { color: '#9fc5e8', fontWeight: 'bold' }
                                        : previousSpeaker[0] === 'F'
                                        ? { color: '#d5a6bd', fontWeight: 'bold' }
                                        : { color: '#fff2cc', fontWeight: 'bold' }
                                }
                            >
                                {'(' + previousSpeaker[0] + ')'}
                            </a>
                        ) : (
                            ''
                        )}
                        <a
                            key={word.WordUID + '_player'}
                            id={word.WordUID + '_player'}
                            onClick={() => {
                                if (isNullOrUndefinedOrZero(word.Dts)) {
                                    return;
                                }
                                // this.props.setPlayerPosition(new Date(word.Dts));
                                if (!isNull(this.props.player.player)) {
                                    this.props.player.player.start(new Date(word.Dts));
                                }
                            }}
                        >
                            {' '}
                            {word.Word}{' '}
                        </a>
                    </React.Fragment>
                );
            } else {
                previousSpeaker = newSpeaker;
                return (
                    <React.Fragment>
                        <a
                            style={
                                previousSpeaker[0] === 'M'
                                    ? { color: '#9fc5e8', fontWeight: 'bold' }
                                    : previousSpeaker[0] === 'F'
                                    ? { color: '#d5a6bd', fontWeight: 'bold' }
                                    : { color: '#fff2cc', fontWeight: 'bold' }
                            }
                        >
                            <br />
                            <br />
                            {'(' + previousSpeaker[0] + ')'}
                        </a>
                        <a
                            key={word.WordUID + '_player'}
                            id={word.WordUID + '_player'}
                            onClick={() => {
                                if (isNullOrUndefinedOrZero(word.Dts)) {
                                    return;
                                }
                                // this.props.setPlayerPosition(new Date(word.Dts));
                                if (!isNull(this.props.player.player)) {
                                    this.props.player.player.start(new Date(word.Dts));
                                }
                            }}
                        >
                            {' '}
                            {word.Word}{' '}
                        </a>
                    </React.Fragment>
                );
            }
        });

        this.props.setCurrentbegin(newBegin);
        this.props.setCurrentend(newEnd);
        this.props.setCurrentsentence(sentence);
        this.props.setCurrenttimestampedwords(myObj);
        this.props.setCurrentwords(newWords);
        this.props.setNewwords([]);
    }

    private highligthPlayerText(time: number) {
        const { classes } = this.props;

        const timestampedWords = this.props.searchSTT.currentTimestampedWords;

        timestampedWords.forEach((value: string, key: number) => {
            const current = document.getElementById(value);
            if (!isNull(current)) {
                if (key > time - 1000 && key < time + 1000) {
                    current.className = classes.highlightedWordText;
                    return;
                }
                current.className = '';
            }
        });
    }

    private renderSTT(): React.ReactNode {
        return (
            <div className={this.props.classes.root}>
                <MD.AppBar className={this.props.classes.tabsSwitcherContainer} color='default' position='static'>
                    <MD.Tabs
                        className={this.props.classes.tabsSwitcher}
                        indicatorColor='primary'
                        onChange={this.handleTabSTTChange.bind(this)}
                        scrollButtons='auto'
                        textColor='primary'
                        value={this.state.tabSTTValue}
                        variant='scrollable'
                    >
                        {this.getSTTTabs()}
                    </MD.Tabs>
                </MD.AppBar>
                <SwipeableViews axis='x' className={this.props.classes.tabsContainer} index={this.state.tabSTTValue}>
                    {this.getTabSTTContents()}
                </SwipeableViews>
            </div>
        );
    }

    private getSTTTabs(): React.ReactNode[] {
        const tabs: React.ReactNode[] = [];

        tabs.push(
            <MD.Tab
                className={this.props.classes.tabsSwitcherTab}
                key='sttTab_1'
                label={this.props.i18n._('Verbatim')}
            />,
        );
        tabs.push(
            <MD.Tab
                className={this.props.classes.tabsSwitcherTab}
                key='sttTab_2'
                label={this.props.i18n._('Search')}
            />,
        );

        return tabs;
    }

    private getTabSTTContents(): React.ReactNode[] {
        const contents: React.ReactNode[] = [];
        contents.push(this.renderSTTCurrentText());
        contents.push(this.renderSTTSearch());
        return contents;
    }

    private renderSTTCurrentText(): React.ReactNode {
        if (!isNullOrUndefined(this.props.searchSTT)) {
            if (!isNullOrUndefinedOrEmptyArray(this.props.searchSTT.newWords)) {
                this.updateText();
            }
            if (!isNullOrUndefinedOrEmptyArray(this.props.searchSTT.currentSentence)) {
                let player: IPlayer | null;
                if (!isNullOrUndefined(this.props.player.player)) {
                    player = this.props.player.player;
                } else {
                    player = null;
                }
                let time: Date;
                time = new Date(0);
                if (!isNull(player)) {
                    const currentTime = player.getCurrentTime();
                    if (!isNull(currentTime)) {
                        time = currentTime;
                        this.highligthPlayerText(time.getTime());
                    }
                }

                return <div>{this.props.searchSTT.currentSentence}</div>;
            }
        }
    }

    private renderSTTSearch(): React.ReactNode {
        return (
            <MD.Typography className={this.props.classes.tabsContent} component='div' key='sttTabContentSearch'>
                <form className={this.props.classes.filterBox} onSubmit={this.onSubmit.bind(this)}>
                    <MD.FormControl
                        className={this.props.classes.formControl + ' ' + this.props.classes.filterBoxInput}
                    >
                        <MD.InputLabel htmlFor='sttTextSearch'>
                            {this.props.i18n._('Search word in timeline')}
                        </MD.InputLabel>
                        <MD.Input
                            endAdornment={
                                <MD.InputAdornment position='end'>
                                    <MD.IconButton
                                        aria-label={this.props.i18n._('Reset filter')}
                                        onClick={this.handleSTTReset.bind(this)}
                                    >
                                        <FontAwesomeIcon icon={FA.faTimesCircle} size='xs' />
                                    </MD.IconButton>
                                </MD.InputAdornment>
                            }
                            id={'sttTextSearch'}
                            onChange={this.handleSTTTextChange.bind(this)}
                        />
                    </MD.FormControl>
                    <MD.Button
                        disabled={isNullOrUndefinedOrEmptyString(this.state.sttTextSearch)}
                        onClick={this.handleSTTSearch.bind(this)}
                    >
                        {this.props.i18n._('Search')}
                    </MD.Button>
                </form>

                <MD.List component='nav' className={this.props.classes.annotationItemContainer}>
                    {this.props.searchSTT.searchLoading ? (
                        <MD.CircularProgress />
                    ) : (
                        this.props.searchSTT.searchSpeechesResults.reduce<React.ReactNode[]>(
                            (
                                list: React.ReactNode[],
                                res: NullableSpeechResult,
                                currentIndex: number,
                            ): React.ReactNode[] => {
                                return list.concat(this.buildListItem(res, currentIndex));
                            },
                            [],
                        )
                    )}
                </MD.List>
            </MD.Typography>
        );
    }

    private buildListItem(res: NullableSpeechResult, currentIndex: number): React.ReactNode[] {
        const listItems: React.ReactNode[] = [];
        if (isNullOrUndefined(res)) {
            return listItems;
        }
        if (isNullOrUndefinedOrZero(res.Start)) {
            return listItems;
        }
        if (isNullOrUndefinedOrZero(res.End)) {
            return listItems;
        }

        const itemNumber = currentIndex;

        listItems.push(
            <MD.ListItem
                button={true}
                className={this.props.classes.annotationItem}
                key={`annotationSttItem_${itemNumber}`}
                onClick={this.handleSTTView.bind(this, res)}
            >
                <MD.ListItemIcon>
                    <div className={this.props.classes.annotationItemHeader}>
                        <div className={this.props.classes.annotationItemHeaderText}>
                            {moment(new Date(res.Start)).format(this.props.localeInfos.formatShortTimeWithSeconds)}
                        </div>
                    </div>
                </MD.ListItemIcon>
                <MD.ListItemText className={this.props.classes.listItemTexts} primary={this.sttSentence(res)} />
            </MD.ListItem>,
        );

        return listItems;
    }

    private sttSentence(res: mediaarchiver.ISearchSpeechResult): React.ReactNode[] {
        const sentence: React.ReactNode[] = [];
        if (isNullOrUndefinedOrEmptyArray(res.Fullwords)) {
            return sentence;
        }
        if (isNullOrUndefinedOrEmptyArray(res.Matches)) {
            return sentence;
        }

        const matches = res.Matches;

        res.Fullwords.forEach((word: Proto.mediaarchiver.ISearchSpeechesWord) => {
            if (!isNullOrUndefinedOrEmptyString(word.Word)) {
                if (matches.includes(word.Word)) {
                    sentence.push(<span className={this.props.classes.sttItemMatch}>{word.Word} </span>);
                } else {
                    sentence.push(word.Word + ' ');
                }
            }
        });

        return sentence;
    }

    private getReadjustedEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.readjustedEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.readjustedEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.readjustedEPGDetails).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.readjustedEPGDetails as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.readjustedCategoryFilter.length === 0) {
                    return true;
                }
                return this.getReadjustedEPGCategory(i[1]) === this.state.readjustedCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.readjustedTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.readjustedTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getBroadcasterEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.broadcasterEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.broadcasterEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.broadcasterEPGDetails).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.broadcasterEPGDetails as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.broadcasterCategoryFilter.length === 0) {
                    return true;
                }
                return this.getBroadcasterEPGCategory(i[1]) === this.state.broadcasterCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.broadcasterTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.broadcasterTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getDeclarativeRadioEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.declarativeRadioEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.declarativeRadioEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        Object.keys(this.props.timeline.data.declarativeRadioEPGDetails).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.declarativeRadioEPGDetails as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.declarativeRadioCategoryFilter.length === 0) {
                    return true;
                }
                return this.getDeclarativeRadioEPGCategory(i[1]) === this.state.declarativeRadioCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.declarativeRadioTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.declarativeRadioTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getBrutPhonosenseEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.brutPhonosenseEPG0).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.brutPhonosenseEPG1).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG1 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.brutPhonosenseEPG2).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG2 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.brutPhonosenseEPG3).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG3 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.brutPhonosenseCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.brutPhonosenseCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.brutPhonosenseTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.brutPhonosenseTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getProcessedResultEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.processedResultEPG0).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.processedResultEPG1).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG1 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.processedResultEPG2).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG2 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.processedResultEPG3).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG3 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.processedResultCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.processedResultCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.processedResultTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.processedResultTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getLeonV1EPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.leonV1EPG0).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.leonV1EPG1).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG1 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.leonV1EPG2).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG2 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.leonV1EPG3).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG3 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.leonV1CategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.leonV1CategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.leonV1TextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.leonV1TextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getMNMRawEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.mnmRawEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnmRawEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.mnmRawCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.mnmRawCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.mnmRawTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.mnmRawTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getMNMProcessedEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.mnmProcessedEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnmProcessedEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.mnmProcessedCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.mnmProcessedCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.mnmProcessedTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.mnmProcessedTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getOCREPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.ocrEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.ocrEPGDetail).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrEPGDetail as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.ocrCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.ocrCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.ocrTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.ocrTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getOCRTitleEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.ocrTitleEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrTitleEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(this.props.timeline.data.ocrTitleEPGDetail).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrTitleEPGDetail as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.ocrTitleCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.ocrTitleCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.ocrTitleTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.ocrTitleTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getOCRCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.ocrEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.ocrEPGDetail).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrEPGDetail as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getOCRTitleCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.ocrTitleEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrTitleEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.ocrTitleEPGDetail).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.ocrTitleEPGDetail as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getExtractResultEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.extractResultEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.extractResultEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });

        return list
            .filter((i) => {
                if (unfiltered || this.state.extractResultCategoryFilter.length === 0) {
                    return true;
                }
                return this.getEPGCategory(i[1]) === this.state.extractResultCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.extractResultTextFilter.length === 0) {
                    return true;
                }
                let title = i[1].title;
                if (!isNullOrUndefinedOrEmptyString(i[1].subtitle)) {
                    title = `${i[1].title}: ${i[1].subtitle}`;
                }
                return title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.extractResultTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getBackgroundCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.backgroundEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.backgroundEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getBackgroundEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getBackgroundSTGCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.backgroundstgEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.backgroundstgEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getBackgroundSTGEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getARPPCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.arppEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.arppEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getARPPEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getMNMXBCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.mnmxbEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnmxbEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getMNMXBEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getMNIONLYCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.mnionlyEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnionlyEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getMNIONLYEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getSponsoringCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.sponsoringEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.sponsoringEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getSponsoringEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getCrossBucketCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.crossbucketEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.crossbucketEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getCrossBucketEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getCrossBucketstgCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.crossbucketstgEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.crossbucketstgEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getCrossBucketstgEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getBroadcasterCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.broadcasterEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.broadcasterEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.broadcasterEPGDetails).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.broadcasterEPGDetails as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getBroadcasterEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getDeclarativeRadioCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.declarativeRadioEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.declarativeRadioEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.declarativeRadioEPGDetails).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.declarativeRadioEPGDetails as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getDeclarativeRadioEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getBrutPhonosenseCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.brutPhonosenseEPG0).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.brutPhonosenseEPG1).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.brutPhonosenseEPG2).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.brutPhonosenseEPG3).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.brutPhonosenseEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });

        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getProcessedResultCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.processedResultEPG0).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.processedResultEPG1).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG1 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.processedResultEPG2).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG2 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.processedResultEPG3).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.processedResultEPG3 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });

        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getLeonV1Categories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.leonV1EPG0).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG0 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.leonV1EPG1).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG1 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.leonV1EPG2).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG2 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.leonV1EPG3).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.leonV1EPG3 as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });

        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getMNMRawCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.mnmRawEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnmRawEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }
    private getMNMProcessedCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.mnmProcessedEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnmProcessedEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getExtractResultCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.extractResultEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.extractResultEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });

        return [
            ...new Set(
                list
                    .map((epg) => this.getEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private getReadjustedCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.readjustedEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.readjustedEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        Object.keys(this.props.timeline.data.readjustedEPGDetails).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.readjustedEPGDetails as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getReadjustedEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    /* Category change */

    private handleBackgroundCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ backgroundCategoryFilter: ev.target.value });
    }
    private handleBackgroundSTGCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ backgroundstgCategoryFilter: ev.target.value });
    }
    private handleARPPCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ arppCategoryFilter: ev.target.value });
    }
    private handleMNMXBCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnmxbCategoryFilter: ev.target.value });
    }
    private handleMNIONLYCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnionlyCategoryFilter: ev.target.value });
    }
    private handleSponsoringCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ sponsoringCategoryFilter: ev.target.value });
    }
    private handleCrossBucketCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ crossbucketCategoryFilter: ev.target.value });
    }
    private handleCrossBucketstgCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ crossbucketstgCategoryFilter: ev.target.value });
    }
    private handleBroadcasterCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ broadcasterCategoryFilter: ev.target.value });
    }
    private handleDeclarativeRadioCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ declarativeRadioCategoryFilter: ev.target.value });
    }
    private handleExtractResultCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ extractResultCategoryFilter: ev.target.value });
    }
    private handleProcessedResultCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ processedResultCategoryFilter: ev.target.value });
    }
    private handleLeonV1CategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ leonV1CategoryFilter: ev.target.value });
    }
    private handleMNMRawCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnmRawCategoryFilter: ev.target.value });
    }
    private handleMNMProcessedCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnmProcessedCategoryFilter: ev.target.value });
    }
    private handleBrutPhonosenseCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ brutPhonosenseCategoryFilter: ev.target.value });
    }
    private handleReadjustedCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ readjustedCategoryFilter: ev.target.value });
    }
    private handleOCRCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ ocrCategoryFilter: ev.target.value });
    }
    private handleOCRTitleCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ ocrTitleCategoryFilter: ev.target.value });
    }

    /* Text change */

    private handleBackgroundTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ backgroundTextFilter: ev.target.value });
    }
    private handleBackgroundSTGTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ backgroundstgTextFilter: ev.target.value });
    }
    private handleARPPTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ arppTextFilter: ev.target.value });
    }
    private handleMNMXBTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnmxbTextFilter: ev.target.value });
    }
    private handleMNIONLYTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnionlyTextFilter: ev.target.value });
    }
    private handleSponsoringTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ sponsoringTextFilter: ev.target.value });
    }
    private handleCrossBucketTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ crossbucketTextFilter: ev.target.value });
    }
    private handleCrossBucketstgTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ crossbucketstgTextFilter: ev.target.value });
    }
    private handleBroadcasterTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ broadcasterTextFilter: ev.target.value });
    }
    private handleDeclarativeRadioTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ declarativeRadioTextFilter: ev.target.value });
    }
    private handleProcessedResultTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ processedResultTextFilter: ev.target.value });
    }
    private handleLeonV1TextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ leonV1TextFilter: ev.target.value });
    }
    private handleMNMRawTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnmRawTextFilter: ev.target.value });
    }
    private handleMNMProcessedTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ mnmProcessedTextFilter: ev.target.value });
    }
    private handleBrutPhonosenseTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ brutPhonosenseTextFilter: ev.target.value });
    }
    private handleExtractResultTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ extractResultTextFilter: ev.target.value });
    }
    private handleReadjustedTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ readjustedTextFilter: ev.target.value });
    }
    private handleSTTTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ sttTextSearch: ev.target.value });
    }
    private handleOCRTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ ocrTextFilter: ev.target.value });
    }
    private handleOCRTitleTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ ocrTitleTextFilter: ev.target.value });
    }

    /* Reset */

    private handleBackgroundReset() {
        this.setState({ backgroundTextFilter: '' });
        const input = document.getElementById('backgroundTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleBackgroundSTGReset() {
        this.setState({ backgroundstgTextFilter: '' });
        const input = document.getElementById('backgroundstgTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleARPPReset() {
        this.setState({ arppTextFilter: '' });
        const input = document.getElementById('arppTextFilter') as HTMLInputElement;
        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleMNMXBReset() {
        this.setState({ mnmxbTextFilter: '' });
        const input = document.getElementById('mnmxbTextFilter') as HTMLInputElement;
        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleMNIONLYReset() {
        this.setState({ mnionlyTextFilter: '' });
        const input = document.getElementById('mnionlyTextFilter') as HTMLInputElement;
        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleSponsoringReset() {
        this.setState({ sponsoringTextFilter: '' });
        const input = document.getElementById('sponsoringTextFilter') as HTMLInputElement;
        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleCrossBucketReset() {
        this.setState({ crossbucketTextFilter: '' });
        const input = document.getElementById('crossbucketTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleCrossBucketstgReset() {
        this.setState({ crossbucketstgTextFilter: '' });
        const input = document.getElementById('crossbucketstgTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleBroadcasterReset() {
        this.setState({ broadcasterTextFilter: '' });
        const input = document.getElementById('broadcasterTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleDeclarativeRadioReset() {
        this.setState({ declarativeRadioTextFilter: '' });
        const input = document.getElementById('declarativeRadioTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleExtractResultReset() {
        this.setState({ extractResultTextFilter: '' });
        const input = document.getElementById('extractResultTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleProcessedResultReset() {
        this.setState({ processedResultTextFilter: '' });
        const input = document.getElementById('processedResultTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleLeonV1Reset() {
        this.setState({ leonV1TextFilter: '' });
        const input = document.getElementById('leonV1TextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleMNMRawReset() {
        this.setState({ mnmRawTextFilter: '' });
        const input = document.getElementById('mnmRawTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleMNMProcessedReset() {
        this.setState({ mnmProcessedTextFilter: '' });
        const input = document.getElementById('mnmProcessedTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleBrutPhonosenseReset() {
        this.setState({ brutPhonosenseTextFilter: '' });
        const input = document.getElementById('brutPhonosenseTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handleReadjustedReset() {
        this.setState({ readjustedTextFilter: '' });
        const input = document.getElementById('readjustedTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private handleOCRReset() {
        this.setState({ ocrTextFilter: '' });
        const input = document.getElementById('ocrTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private handleOCRTitleReset() {
        this.setState({ ocrTitleTextFilter: '' });
        const input = document.getElementById('ocrTitleTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private handleSTTReset() {
        this.setState({ sttTextSearch: '' });
        const input = document.getElementById('sttTextSearch') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }
    private handlePoliticalReset() {
        this.setState({
            politicalSelected: [],
            politicalStudyTypeFilter: '',
            politicalTalkTypeFilter: '',
            politicalTimeTypeFilter: '',
        });
    }

    /* Ordered */

    private getDeclarativeEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.declarativeEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.declarativeEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.declarativeCategoryFilter.length === 0) {
                    return true;
                }

                return this.getDeclarativeCategory(i[1]) === this.state.declarativeCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.declarativeTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.declarativeTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getDeclarativeCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.declarativeEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.declarativeEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map<string>(this.getDeclarativeCategory.bind(this))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private handleDeclarativeCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ declarativeCategoryFilter: ev.target.value });
    }

    private handleDeclarativeTextChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ declarativeTextFilter: ev.target.value });
    }

    private handleDeclarativeReset() {
        this.setState({ declarativeTextFilter: '' });
        const input = document.getElementById('declarativeTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private getMusicEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.musicEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.musicEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.musicCategoryFilter.length === 0) {
                    return true;
                }
                return this.getMusicEPGCategory(i[1]) === this.state.musicCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.musicTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.musicTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getBackgroundEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.backgroundEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.backgroundEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.backgroundCategoryFilter.length === 0) {
                    return true;
                }
                return this.getBackgroundEPGCategory(i[1]) === this.state.backgroundCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.backgroundTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.backgroundTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getBackgroundSTGEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.backgroundstgEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.backgroundstgEPG as any)[i];
            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.backgroundstgCategoryFilter.length === 0) {
                    return true;
                }
                return this.getBackgroundEPGCategory(i[1]) === this.state.backgroundstgCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.backgroundstgTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.backgroundstgTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getARPPEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.arppEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.arppEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.arppCategoryFilter.length === 0) {
                    return true;
                }
                return this.getARPPEPGCategory(i[1]) === this.state.arppCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.arppTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.arppTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getMNMXBEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.mnmxbEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnmxbEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.mnmxbCategoryFilter.length === 0) {
                    return true;
                }
                return this.getMNMXBEPGCategory(i[1]) === this.state.mnmxbCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.mnmxbTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.mnmxbTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getMNIONLYEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.mnionlyEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.mnionlyEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.mnionlyCategoryFilter.length === 0) {
                    return true;
                }
                return this.getMNIONLYEPGCategory(i[1]) === this.state.mnionlyCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.mnionlyTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.mnionlyTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getSponsoringEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.sponsoringEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.sponsoringEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.sponsoringCategoryFilter.length === 0) {
                    return true;
                }
                return this.getSponsoringEPGCategory(i[1]) === this.state.sponsoringCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.sponsoringTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.sponsoringTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getCrossBucketEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.crossbucketEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.crossbucketEPG as any)[i];
            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.crossbucketCategoryFilter.length === 0) {
                    return true;
                }
                return this.getCrossBucketEPGCategory(i[1]) === this.state.crossbucketCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.crossbucketTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.crossbucketTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }
    private getCrossBucketstgEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.crossbucketstgEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.crossbucketstgEPG as any)[i];
            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.crossbucketstgCategoryFilter.length === 0) {
                    return true;
                }
                return this.getCrossBucketstgEPGCategory(i[1]) === this.state.crossbucketstgCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.crossbucketstgTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.crossbucketstgTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getMusicCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.musicEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.musicEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getMusicEPGCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private handleMusicCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ musicCategoryFilter: ev.target.value });
    }

    private handleMusicTextChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ musicTextFilter: ev.target.value });
    }

    private handleMusicReset() {
        this.setState({ musicTextFilter: '' });
        const input = document.getElementById('musicTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private getTalkEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const dateEnd = this.props.timeline.criterias.end.getTime();
        const dateStart = this.props.timeline.criterias.start.getTime();
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.talkEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.talkEPG as any)[i];

            if (
                !isNullOrUndefined(epg) &&
                epg.start.getTime() >= dateStart &&
                epg.start.getTime() + epg.duration <= dateEnd
            ) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => unfiltered || this.filterTalk(i[1]))
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private handlePoliticalStudyTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ politicalStudyTypeFilter: ev.target.value });
        this.props.setCopiedTalks([]);
    }

    private handlePoliticalTalkTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ politicalTalkTypeFilter: ev.target.value });
        this.props.setCopiedTalks([]);
    }

    private handlePoliticalTimeTypeChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ politicalTimeTypeFilter: ev.target.value });
        this.props.setCopiedTalks([]);
    }

    private getAnnotationEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        Object.keys(this.props.timeline.data.annotationsEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.annotationsEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.annotationCategoryFilter.length === 0) {
                    return true;
                }
                return this.getAnnotationCategory(i[1]) === this.state.annotationCategoryFilter;
            })
            .filter((i) => {
                if (unfiltered || this.state.annotationTextFilter.length === 0) {
                    return true;
                }
                return this.getAnnotationValue(i[1])
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.annotationTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getAnnotationCategories(): string[] {
        const list: IEPGProgramState[] = [];

        Object.keys(this.props.timeline.data.annotationsEPG).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (this.props.timeline.data.annotationsEPG as any)[i];

            if (!isNullOrUndefined(epg)) {
                list.push(epg);
            }
        });
        return [
            ...new Set(
                list
                    .map((epg) => this.getAnnotationCategory(epg))
                    .filter((i) => !isNullOrUndefinedOrEmptyString(i))
                    .sort(),
            ),
        ] as string[];
    }

    private handleAnnotationCategoryChange(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        this.setState({ annotationCategoryFilter: ev.target.value });
    }

    private handleAnnotationTextChange(ev: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ annotationTextFilter: ev.target.value });
    }

    private handleAnnotationReset() {
        this.setState({ annotationTextFilter: '', contextualMenuProgram: null });
        const input = document.getElementById('annotationTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private getAnnotationCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.typeName)) {
            return epg.metas.typeName === '' ? this.props.i18n._('Deleted type') : epg.metas.typeName;
        }
        return 'N/A';
    }

    private getAnnotationValue(epg: IEPGProgramState): string {
        if (isNullOrUndefined(epg) || isNullOrUndefined(epg.metas) || isNullOrUndefined(epg.metas.data)) {
            return '';
        }

        const list: Map<string, string> = new Map();

        list.set(
            this.props.i18n._('Owner'),
            !isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && epg.metas.owned === 'true'
                ? this.props.i18n._('Yes')
                : this.props.i18n._('No'),
        );

        try {
            const fields = JSON.parse(epg.metas.data);

            if (!Array.isArray(fields)) {
                return '';
            }
            fields.forEach((field) => {
                if (!field.label || !field.type || !field.value) {
                    return;
                }
                switch (field.type) {
                    case 'shortText':
                    case 'longText':
                    case 'number':
                        const value = field.value.length > 50 ? field.value.substr(0, 47) + ' ...' : field.value;

                        list.set(field.label, value);
                        break;
                    case 'boolean':
                        list.set(field.label, field.value === '1' ? this.props.i18n._('Yes') : this.props.i18n._('No'));
                        break;
                    case 'date':
                        const date = new Date(parseInt(field.value, 10) * 1000);

                        if (isNaN(date.getTime())) {
                            return;
                        }
                        list.set(
                            field.label,
                            moment(date).format(
                                field.special
                                    ? this.props.localeInfos.formatLongDateTime
                                    : this.props.localeInfos.formatLongDate,
                            ),
                        );
                        break;
                    case 'list':
                        try {
                            const values = JSON.parse(field.value);

                            if (!Array.isArray(values)) {
                                throw new Error('Value is not an array');
                            }
                            list.set(field.label, values.join(', '));
                        } catch (err) {
                            if (typeof field.value === 'string') {
                                list.set(field.label, field.value);
                            } else {
                                Logger.warn(err as string);
                            }
                        }
                        break;
                    default:
                        return;
                }
            });
            return Array.from(list.entries())
                .map(([key, value]) => `${key}: ${value}`)
                .join(' | ');
        } catch (err) {
            Logger.warn(err as string);
            return '';
        }
    }

    private getBackgroundEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getBackgroundSTGEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getARPPEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getMNMXBEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getMNIONLYEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getSponsoringEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getCrossBucketEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getCrossBucketstgEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && !isNullOrUndefined(epg.metas.bucket)) {
            return epg.metas.bucket;
        }
        return this.props.i18n._('Other');
    }
    private getBroadcasterEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
            try {
                return (
                    (!isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas.idShowKindComplete)
                        ? `[${epg.metas.idShowKindComplete}] `
                        : '') + (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ')
                );
            } catch (err) {
                Logger.warn(err as string);
            }
        }
        return this.props.i18n._('Other');
    }
    private getDeclarativeRadioEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
            try {
                return (
                    (!isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas.idShowKindComplete)
                        ? `[${epg.metas.idShowKindComplete}] `
                        : '') + (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ')
                );
            } catch (err) {
                Logger.warn(err as string);
            }
        }
        return this.props.i18n._('Other');
    }

    private getEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefinedOrEmptyString(epg.category)) {
            return `[${epg.category}] `;
        }
        return this.props.i18n._('Other');
    }

    private getReadjustedEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && epg.metas.hierarchyShowKind) {
            try {
                return (
                    (!isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas.idShowKindComplete)
                        ? `[${epg.metas.idShowKindComplete}] `
                        : '') + (JSON.parse(epg.metas.hierarchyShowKind) as string[]).join(' > ')
                );
            } catch (err) {
                Logger.warn(err as string);
            }
        }
        return this.props.i18n._('Other');
    }

    private getMusicEPGCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && epg.metas.genre) {
            return epg.metas.genre;
        }
        return this.props.i18n._('Other');
    }

    private getTalkEPG(epg: IEPGProgramState): string {
        let speakers: mediaarchiver.ITalkSpeaker[] = [];

        if (
            !isNullOrUndefined(epg) &&
            !isNullOrUndefined(epg.metas) &&
            !isNullOrUndefinedOrEmptyString(epg.metas.speakers)
        ) {
            try {
                speakers = JSON.parse(epg.metas.speakers);
            } catch (err) {
                Logger.warn({ error: err, value: epg.metas.speakers }, 'Failed unmarshaling speakers');
            }
        }
        if (speakers.length === 0) {
            return '';
        }
        if (
            !isNullOrUndefined(epg) &&
            !isNullOrUndefined(epg.metas) &&
            !isNullOrUndefinedOrEmptyString(epg.metas.talk_type) &&
            epg.metas.talk_type === '2'
        ) {
            if (speakers.length > 5) {
                return (
                    this.props.i18n.sprintf(
                        this.props.i18n.ngettext('%1$d speaker', '%1$d speakers', speakers.length),
                        speakers.length,
                    ) +
                    ' | ' +
                    limitText(this.getTalkEPGStudy(epg), 50)
                );
            }
            return (
                speakers
                    .map((s: mediaarchiver.ITalkSpeaker) => {
                        if (!isNullOrUndefined(s) && !isNullOrUndefinedOrEmptyString(s.Name)) {
                            return s.Name;
                        }
                        return null;
                    })
                    .filter((s: string | null) => !isNull(s))
                    .join(', ') +
                ' | ' +
                limitText(this.getTalkEPGStudy(epg), 50)
            );
        }
        return (
            this.getTalkEPGDuration(epg) +
            ' | ' +
            limitText(this.getTalkEPGGroup(speakers[0].Group), 50) +
            ' | ' +
            limitText(this.getTalkEPGTitle(speakers[0].Title), 50) +
            ' | ' +
            limitText(this.getTalkEPGRegim(speakers[0].Regim), 50) +
            ' | ' +
            limitText(this.getTalkEPGStudy(epg), 50)
        );
    }

    private getTalkEPGGroup(group: number | null | undefined): string {
        let name = this.props.i18n._('Other');
        if (!isNullOrUndefined(group)) {
            this.props.talk.allGroups.some((g: mediaarchiver.ITalkGroup) => {
                if (
                    !isNullOrUndefined(g) &&
                    !isNullOrUndefinedOrZero(g.ID) &&
                    !isNullOrUndefinedOrEmptyString(g.Name) &&
                    g.ID == group
                ) {
                    name = g.Name;
                }
            });
        }
        return name;
    }

    private getTalkEPGTitle(title: string | null | undefined): string {
        if (!isNullOrUndefinedOrEmptyString(title)) {
            return title.toLocaleUpperCase();
        }
        return this.props.i18n._('Other');
    }

    private getTalkEPGRegim(regim: number | null | undefined): string {
        let name = this.props.i18n._('Other');

        if (!isNullOrUndefined(regim)) {
            this.props.talk.allRegims.some((r: mediaarchiver.ITalkRegim) => {
                if (
                    !isNullOrUndefined(r) &&
                    !isNullOrUndefinedOrZero(r.ID) &&
                    !isNullOrUndefinedOrEmptyString(r.Name) &&
                    r.ID == regim
                ) {
                    name = r.Name;
                }
            });
        }
        return name;
    }

    private getTalkEPGStudy(epg: IEPGProgramState): string {
        let name = this.props.i18n._('Other');

        if (!isNullOrUndefined(epg) && !isNullOrUndefined(epg.metas) && epg.metas.studyType) {
            const studyID = parseInt(epg.metas.studyType, 10);

            this.props.talk.studies.some((s: mediaarchiver.ITalkStudy) => {
                if (
                    !isNullOrUndefined(s) &&
                    !isNullOrUndefinedOrZero(s.ID) &&
                    !isNullOrUndefinedOrEmptyString(s.Name) &&
                    s.ID == studyID
                ) {
                    name = s.Name;
                }
            });
        }
        return name;
    }

    private getTalkEPGDuration(epg: IEPGProgramState): string {
        if (!isNullOrUndefined(epg) && !isNullOrUndefinedOrZero(epg.realDuration)) {
            const duration = moment.duration(epg.realDuration);

            return (
                ('0' + duration.hours().toString()).slice(-2) +
                ':' +
                ('0' + duration.minutes().toString()).slice(-2) +
                ':' +
                ('0' + duration.seconds().toString()).slice(-2)
            );
        }
        return 'N/A';
    }

    private filterTalk(epg: IEPGProgramState): boolean {
        if (isNullOrUndefined(epg) || isNullOrUndefined(epg.metas)) {
            return false;
        }

        const studyID = parseInt(epg.metas.studyType, 10);

        if (
            this.state.politicalStudyTypeFilter.length !== 0 &&
            !this.props.talk.studies.some(
                (s: mediaarchiver.ITalkStudy) =>
                    !isNullOrUndefined(s) &&
                    s.ID === studyID &&
                    (s.Type || 0).toString(10) === this.state.politicalStudyTypeFilter,
            )
        ) {
            return false;
        }
        if (
            this.state.politicalTalkTypeFilter.length !== 0 &&
            epg.metas.talk_type !== this.state.politicalTalkTypeFilter
        ) {
            return false;
        }
        if (
            this.state.politicalTimeTypeFilter.length !== 0 &&
            epg.metas.time_type !== this.state.politicalTimeTypeFilter
        ) {
            return false;
        }
        return true;
    }

    private getXMLEPGOrdered(unfiltered = false): IEPGProgramState[] {
        const list: Array<[number, IEPGProgramState]> = [];

        if (!this.props.xml.files.has(this.props.xml.currentFile)) {
            return [];
        }
        const lines = this.props.xml.files.get(this.props.xml.currentFile) as IEPGState[];

        if (!Array.isArray(lines) || lines.length !== 2) {
            return [];
        }
        const minTS = this.props.timeline.criterias.start.getTime();
        const maxTS = this.props.timeline.criterias.end.getTime();
        Object.keys(lines[0]).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (lines[0] as any)[i];

            if (
                !isNullOrUndefined(epg) &&
                !isNullOrUndefined(epg.start) &&
                epg.start.getTime() >= minTS &&
                epg.start.getTime() <= maxTS
            ) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        Object.keys(lines[1]).forEach((i) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const epg: IEPGProgramState = (lines[1] as any)[i];

            if (
                !isNullOrUndefined(epg) &&
                !isNullOrUndefined(epg.start) &&
                epg.start.getTime() >= minTS &&
                epg.start.getTime() <= maxTS
            ) {
                list.push([epg.start.getTime(), epg]);
            }
        });
        return list
            .filter((i) => {
                if (unfiltered || this.state.xmlTextFilter.length === 0) {
                    return true;
                }
                return i[1].title
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLocaleLowerCase()
                    .includes(
                        this.state.xmlTextFilter
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .toLocaleLowerCase(),
                    );
            })
            .sort((a, b) => a[0] - b[0])
            .map((i) => i[1]);
    }

    private getDeclarativeCategory(epg: IEPGProgramState): string {
        if (!isNullOrUndefinedOrEmptyString(epg.categoryText)) {
            return epg.categoryText;
        }
        if (!isNullOrUndefined(epg.metas) && !isNullOrUndefinedOrEmptyString(epg.metas['nielsen type form'])) {
            return epg.metas['nielsen type form'];
        }
        return this.props.i18n._('Unknown category');
    }

    private handleXMLTextChange(ev: React.ChangeEvent<HTMLInputElement>) {
        if (this.xmlTimer !== -1) {
            window.clearTimeout(this.xmlTimer);
        }
        const value = ev.target.value;
        this.xmlTimer = window.setTimeout(() => {
            this.setState({ xmlTextFilter: value });
        }, 500);
    }

    private handleXMLReset() {
        this.setState({ xmlTextFilter: '' });
        const input = document.getElementById('xmlTextFilter') as HTMLInputElement;

        if (input === null) {
            return;
        }
        input.value = '';
        input.dispatchEvent(new FocusEvent('blur'));
    }

    private onXMLErrorClose() {
        this.props.setXMLError('');
    }

    // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
    private handleTabChange(event: React.ChangeEvent<{}>, value: any) {
        const tab: number = parseInt(value, 10);
        if (!isNaN(tab)) {
            this.setState({ tabValue: tab });
        }
    }

    // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
    private handleTabSTTChange(event: React.ChangeEvent<{}>, value: any) {
        const tab: number = parseInt(value, 10);
        if (!isNaN(tab)) {
            this.setState({ tabSTTValue: tab });
        }
    }

    private handleProgramView(prg: IEPGProgramState) {
        if (isNullOrUndefined(this.props.player.player)) {
            return;
        }
        this.props.player.player.start(
            prg.start,
            this.props.timeline.criterias.end,
            this.props.user.user.options.indexOf(
                mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
            ) === -1,
        );

        const btn = document.getElementById('centerTimelineButton');

        if (btn !== null) {
            window.setTimeout(() => {
                btn.click();
            }, 2000);
        }
    }

    private handleSTTView(res: NullableSpeechResult) {
        if (isNullOrUndefined(this.props.player.player)) {
            return;
        }
        if (
            isNullOrUndefined(res) ||
            isNullOrUndefinedOrEmptyArray(res.Matches) ||
            isNullOrUndefinedOrEmptyArray(res.Fullwords)
        ) {
            return;
        }
        const matches = res.Matches;

        const match = res.Fullwords.find((element) => {
            if (!isNullOrUndefinedOrEmptyString(element.Word)) {
                if (matches.includes(element.Word)) {
                    return element.Word;
                }
            }
        });

        if (isNullOrUndefined(match) || isNullOrUndefined(match.Dts)) {
            return;
        }

        this.setState({ tabSTTValue: 0 });
        this.props.player.player.start(
            new Date(match.Dts),
            this.props.timeline.criterias.end,
            this.props.user.user.options.indexOf(
                mediaarchiver.UserOptions.USER_OPTION_NO_AUTOPLAY_WHEN_PLAY_CONTENT,
            ) === -1,
        );

        const btn = document.getElementById('centerTimelineButton');

        if (btn !== null) {
            window.setTimeout(() => {
                btn.click();
            }, 2000);
        }
    }

    private handleXMLFile(ev: React.ChangeEvent<HTMLInputElement>) {
        if (isNullOrUndefined(ev.target) || isNullOrUndefined(ev.target.files) || ev.target.files.length < 1) {
            return;
        }
        this.props.loadXMLFile(ev.target.files[0]);
        ev.target.value = '';
    }

    private clearXMLFile() {
        this.props.clearList();
    }

    private onChangeFile(ev: React.ChangeEvent<{ name?: string; value: unknown }>) {
        if (!ev.target.value) {
            return;
        }
        this.props.setCurrentXMLFile(ev.target.value as string);
    }

    private onSubmit(ev: React.FormEvent) {
        ev.stopPropagation();
        ev.preventDefault();
    }

    private handleContext(prg: IEPGProgramState, ev: React.MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        this.contextualMenu = ev.target as HTMLDivElement;
        this.setState({ contextualMenuProgram: prg });
    }

    private onArchiveAnnotation() {
        if (isNull(this.state.contextualMenuProgram) || isNull(this.props.player.player)) {
            return;
        }
        this.props.player.player.start(this.state.contextualMenuProgram.start, this.props.timeline.criterias.end);
        const program = this.state.contextualMenuProgram;
        window.setTimeout(() => {
            if (isNull(this.props.player.player)) {
                return;
            }
            this.props.player.player
                .getSnapshot()
                .then((snap: string) => {
                    this.props.setSnapshot(snap);
                })
                .catch((err) => Logger.warn(err as Error, 'Could not generate snapshot'));
            this.props.setPlayerPosition(program.start);
            this.props.setStartSelection(program.start);
            this.props.setEndSelection(new Date(program.start.getTime() + program.realDuration));
            this.props.showExtractionDialog();
            this.setState({ contextualMenuProgram: null });
            this.contextualMenu = document.createElement('div');
        }, 1500);
    }

    private onEditAnnotation() {
        if (
            isNull(this.state.contextualMenuProgram) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas.id)
        ) {
            return;
        }
        this.props.openEditDialog({
            Data: JSON.stringify(this.state.contextualMenuProgram.metas),
            DurationMS: this.state.contextualMenuProgram.duration,
            ID: this.state.contextualMenuProgram.metas.id,
            MediaID: this.props.timeline.criterias.media,
            Start: this.state.contextualMenuProgram.start.getTime(),
            Type: this.state.contextualMenuProgram.metas.type,
        });
        this.setState({ contextualMenuProgram: null });
        this.contextualMenu = document.createElement('div');
    }

    private onDeleteAnnotation() {
        if (
            isNull(this.state.contextualMenuProgram) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas) ||
            isNullOrUndefined(this.state.contextualMenuProgram.metas.id)
        ) {
            return;
        }
        this.props.deleteAnnotation({
            ID: this.state.contextualMenuProgram.metas.id,
        });
        this.setState({ contextualMenuProgram: null });
        this.contextualMenu = document.createElement('div');
    }

    private handleSTTSearch() {
        this.props.clearSTTSearch();
        this.props.doSTTSearchSpeeches({
            end: this.props.timeline.criterias.end,
            logic: Proto.mediaarchiver.SearchSpeechesLogic.SPEECH_SEARCH_LOGIC_AND,
            medias: [this.props.timeline.criterias.media],
            page: 0,
            pageSize: 50,
            start: this.props.timeline.criterias.start,
            text: this.state.sttTextSearch,
        });
    }
}
