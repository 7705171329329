import moment from 'moment';
import hash from 'object-hash';
import { Reducer, Store } from 'redux';
import { action as newAction } from 'typesafe-actions';

import { Backend } from '../../Backend';
import { IFromCommand } from '../../Backend/Commands';
import { EPG } from '../../Backend/Commands/EPG';
import { store } from '../../Store/store';
import { Logger } from '../../Utils/Logger';
import { random } from '../../Utils/String';
import { TypedStorage } from '../../Utils/TypedStorage';
import { isNull } from '../../Utils/Various';
import { MediaTypes } from '../Medias/Types';
import { GetAllZooms, GetInitialZoom, GetZooms } from './Constants';
import { ITimelineCriteriasState, ITimelineState, IZoom, TimelineActionTypes } from './Types';

const defaultStart = moment().subtract(1, 'day').startOf('day').toDate();
const tsStart = TypedStorage.get('lastTimelineDate', defaultStart.getTime());

const initialState: ITimelineState = {
    criterias: {
        active: false,
        archiveTitle: '',
        calendar: {
            duration: TypedStorage.get('calendarDuration', 4),
            start: new Date(tsStart),
            startWarning: '',
        },
        contentTypes: [],
        dataToken: '',
        end: new Date(),
        incrementGenerate: 0,
        media: 0,
        mediaName: '',
        mediaType: MediaTypes.TV,
        playerStart: new Date(),
        sender: '',
        show: false,
        start: new Date(),
        streamerToken: '',
        timezone: 'Europe/Paris',
        type: '',
    },
    criteriasHash: '',
    data: {
        annotationsEPG: [],
        arppEPG: [],
        audienceEPG: [],
        backgroundEPG: [],
        backgroundstgEPG: [],
        broadcasterEPG: [],
        broadcasterEPGDetails: [],
        brutPhonosenseEPG0: [],
        brutPhonosenseEPG1: [],
        brutPhonosenseEPG2: [],
        brutPhonosenseEPG3: [],
        crossbucketEPG: [],
        crossbucketstgEPG: [],
        declarativeEPG: [],
        declarativeRadioEPG: [],
        declarativeRadioEPGDetails: [],
        extractResultEPG: [],
        leonV1EPG0: [],
        leonV1EPG1: [],
        leonV1EPG2: [],
        leonV1EPG3: [],
        mnionlyEPG: [],
        mnmProcessedEPG: [],
        mnmRawEPG: [],
        mnmxbEPG: [],
        musicEPG: [],
        ocrEPG: [],
        ocrEPGDetail: [],
        ocrTitleEPG: [],
        ocrTitleEPGDetail: [],
        processedResultEPG0: [],
        processedResultEPG1: [],
        processedResultEPG2: [],
        processedResultEPG3: [],
        readjustedEPG: [],
        readjustedEPGDetails: [],
        sponsoringEPG: [],
        sttEPG: [],
        talkEPG: [],
    },
    data2: {},
    events: document.createElement('DIV'),
    height: 0,
    needZoomUpdate: false,
    popoverBroadcaster: null,
    popoverDeclarative: null,
    popoverDeclarativeRadio: null,
    popoverReadjusted: null,
    presentation: {
        audienceMode: TypedStorage.get('audienceMode', 'bars'),
        audienceWithVOSDAL: TypedStorage.get('audienceWithVOSDAL', false),
        playbackSpeed: 1,
        zoom: GetInitialZoom(GetAllZooms()),
    },
    ready: false,
    reportBugErrors: new Map(),
    reportingBug: false,
    showReportBug: false,
    startContentStep: 0,
    storageBugShow: !TypedStorage.get('storageBugSeen', false),
    versionTag: random(),
    workInProgress: false,
};

const reducer: Reducer<ITimelineState> = (state = initialState, action) => {
    switch (action.type) {
        case TimelineActionTypes.CENTER: {
            state.events.dispatchEvent(new CustomEvent('center'));
            return state;
        }

        case TimelineActionTypes.HIDE: {
            return { ...state, show: false };
        }

        case TimelineActionTypes.HIDE_REPORT_BUG: {
            return { ...state, showReportBug: false };
        }

        case TimelineActionTypes.MOVE_STARTCONTENT_STEP: {
            return {
                ...state,
                startContentStep: state.startContentStep + action.payload,
            };
        }

        case TimelineActionTypes.SET_AUDIENCE_MODE: {
            switch (action.payload) {
                case 'bars':
                case 'dots':
                    TypedStorage.set('audienceMode', action.payload);
                    return {
                        ...state,
                        presentation: {
                            ...state.presentation,
                            audienceMode: action.payload,
                        },
                    };
                default:
                    Logger.warn({ mode: action.payload }, 'Unknown audience mode');
                    return state;
            }
        }

        case TimelineActionTypes.SET_AUDIENCE_VOSDAL: {
            TypedStorage.set('audienceWithVOSDAL', action.payload);
            return {
                ...state,
                presentation: {
                    ...state.presentation,
                    audienceWithVOSDAL: action.payload,
                },
            };
        }

        case TimelineActionTypes.SET_CALENDAR_DURATION: {
            TypedStorage.set('calendarDuration', action.payload);
            return {
                ...state,
                criterias: {
                    ...state.criterias,
                    calendar: {
                        duration: action.payload,
                        start: state.criterias.calendar.start,
                        startWarning: state.criterias.calendar.startWarning,
                    },
                },
            };
        }

        case TimelineActionTypes.SET_CALENDAR_START: {
            TypedStorage.set('lastTimelineDate', action.payload.getTime());
            return {
                ...state,
                criterias: {
                    ...state.criterias,
                    calendar: {
                        duration: state.criterias.calendar.duration,
                        start: action.payload,
                        startWarning: state.criterias.calendar.startWarning,
                    },
                },
            };
        }

        case TimelineActionTypes.SET_CALENDAR_START_WARNING: {
            return {
                ...state,
                criterias: {
                    ...state.criterias,
                    calendar: {
                        duration: state.criterias.calendar.duration,
                        start: state.criterias.calendar.start,
                        startWarning: action.payload,
                    },
                },
            };
        }

        case TimelineActionTypes.SET_EPGS: {
            const data = {
                ...state.data,
                annotationsEPG: !isNull(action.payload.annotations) ? action.payload.annotations : [],
                arppEPG: !isNull(action.payload.arpp) ? action.payload.arpp : [],
                audienceEPG: !isNull(action.payload.audiences) ? action.payload.audiences : [],
                backgroundEPG: !isNull(action.payload.background) ? action.payload.background : [],
                backgroundstgEPG: !isNull(action.payload.backgroundstg) ? action.payload.backgroundstg : [],
                broadcasterEPG: !isNull(action.payload.broadcaster) ? action.payload.broadcaster[0] : [],
                broadcasterEPGDetails: !isNull(action.payload.broadcaster) ? action.payload.broadcaster[1] : [],
                brutPhonosenseEPG0: !isNull(action.payload.raw) ? action.payload.raw[0] : [],
                brutPhonosenseEPG1: !isNull(action.payload.raw) ? action.payload.raw[1] : [],
                brutPhonosenseEPG2: !isNull(action.payload.raw) ? action.payload.raw[2] : [],
                brutPhonosenseEPG3: !isNull(action.payload.raw) ? action.payload.raw[3] : [],
                crossbucketEPG: !isNull(action.payload.crossbucket) ? action.payload.crossbucket : [],
                crossbucketstgEPG: !isNull(action.payload.crossbucketstg) ? action.payload.crossbucketstg : [],
                declarativeEPG: !isNull(action.payload.declarative) ? action.payload.declarative : [],
                declarativeRadioEPG: !isNull(action.payload.declarativeRadio) ? action.payload.declarativeRadio[0] : [],
                declarativeRadioEPGDetails: !isNull(action.payload.declarativeRadio)
                    ? action.payload.declarativeRadio[1]
                    : [],
                extractResultEPG: !isNull(action.payload.extract) ? action.payload.extract : [],
                leonV1EPG0: !isNull(action.payload.leonV1) ? action.payload.leonV1[0] : [],
                leonV1EPG1: !isNull(action.payload.leonV1) ? action.payload.leonV1[1] : [],
                leonV1EPG2: !isNull(action.payload.leonV1) ? action.payload.leonV1[2] : [],
                leonV1EPG3: !isNull(action.payload.leonV1) ? action.payload.leonV1[3] : [],
                mnionlyEPG: !isNull(action.payload.mnionly) ? action.payload.mnionly : [],
                mnmProcessedEPG: !isNull(action.payload.mnmProcessed) ? action.payload.mnmProcessed : [],
                mnmRawEPG: !isNull(action.payload.mnmRaw) ? action.payload.mnmRaw : [],
                mnmxbEPG: !isNull(action.payload.mnmxb) ? action.payload.mnmxb : [],
                musicEPG: !isNull(action.payload.music) ? action.payload.music : [],
                ocrEPG: !isNull(action.payload.ocr) ? action.payload.ocr[0] : [],
                ocrEPGDetail: !isNull(action.payload.ocr) ? action.payload.ocr[1] : [],
                ocrTitleEPG: !isNull(action.payload.ocrTitle) ? action.payload.ocrTitle[0] : [],
                ocrTitleEPGDetail: !isNull(action.payload.ocrTitle) ? action.payload.ocrTitle[1] : [],
                processedResultEPG0: !isNull(action.payload.processed) ? action.payload.processed[0] : [],
                processedResultEPG1: !isNull(action.payload.processed) ? action.payload.processed[1] : [],
                processedResultEPG2: !isNull(action.payload.processed) ? action.payload.processed[2] : [],
                processedResultEPG3: !isNull(action.payload.processed) ? action.payload.processed[3] : [],
                readjustedEPG: !isNull(action.payload.readjusted) ? action.payload.readjusted[0] : [],
                readjustedEPGDetails: !isNull(action.payload.readjusted) ? action.payload.readjusted[1] : [],
                sponsoringEPG: !isNull(action.payload.sponsoring) ? action.payload.sponsoring : [],
                sttEPG: !isNull(action.payload.stt) ? action.payload.stt : [],
                talkEPG: !isNull(action.payload.talk) ? action.payload.talk : [],
            };

            const data2 = action.payload.newEPG;

            const presentation = { ...state.presentation };

            if (state.needZoomUpdate) {
                presentation.zoom = getStartZoom(state.criterias);
            }
            return {
                ...state,
                data,
                data2,
                needZoomUpdate: false,
                presentation,
                ready: true,
            };
        }

        case TimelineActionTypes.SET_HEIGHT: {
            return {
                ...state,
                height: action.payload,
            };
        }

        case TimelineActionTypes.SET_PLAYBACK_SPEED: {
            let playbackSpeed = action.payload;
            switch (playbackSpeed) {
                case 0.2:
                case 0.5:
                case 2:
                case 5:
                    break;
                case 1:
                default:
                    playbackSpeed = 1;
            }
            return {
                ...state,
                presentation: {
                    ...state.presentation,
                    playbackSpeed,
                },
            };
        }

        case TimelineActionTypes.SET_REPORT_BUG_ERRORS: {
            return { ...state, reportBugErrors: action.payload };
        }

        case TimelineActionTypes.SET_STORAGE_BUG_SEEN: {
            TypedStorage.set('storageBugSeen', true);
            return { ...state, storageBugShow: false };
        }

        case TimelineActionTypes.SHOW_REPORT_BUG: {
            return { ...state, showReportBug: true };
        }

        case TimelineActionTypes.SHOW_STORAGE_BUG: {
            return { ...state, storageBugShow: true };
        }

        case TimelineActionTypes.SHOW: {
            return { ...state, show: true };
        }

        case TimelineActionTypes.UPDATE: {
            return { ...state, version: random() };
        }

        case TimelineActionTypes.UPDATE_CRITERIAS: {
            const newHash = hash({ ...action.payload, generated: new Date().getTime() });
            const newState = { ...state, criterias: action.payload, startContentStep: 0 };

            newState.criteriasHash = newHash;
            window.setTimeout(() => {
                (store as Store).dispatch(newAction(TimelineActionTypes.UPDATE_TIMELINE));
            }, 50);
            state.events.dispatchEvent(new CustomEvent('left'));
            return {
                ...newState,
                data: {
                    annotationsEPG: [],
                    arppEPG: [],
                    audienceEPG: [],
                    backgroundEPG: [],
                    backgroundstgEPG: [],
                    broadcasterEPG: [],
                    broadcasterEPGDetails: [],
                    brutPhonosenseEPG0: [],
                    brutPhonosenseEPG1: [],
                    brutPhonosenseEPG2: [],
                    brutPhonosenseEPG3: [],
                    crossbucketEPG: [],
                    crossbucketstgEPG: [],
                    declarativeEPG: [],
                    declarativeRadioEPG: [],
                    declarativeRadioEPGDetails: [],
                    extractResultEPG: [],
                    leonV1EPG0: [],
                    leonV1EPG1: [],
                    leonV1EPG2: [],
                    leonV1EPG3: [],
                    mnionlyEPG: [],
                    mnmProcessedEPG: [],
                    mnmRawEPG: [],
                    mnmxbEPG: [],
                    musicEPG: [],
                    ocrEPG: [],
                    ocrEPGDetail: [],
                    ocrTitleEPG: [],
                    ocrTitleEPGDetail: [],
                    processedResultEPG0: [],
                    processedResultEPG1: [],
                    processedResultEPG2: [],
                    processedResultEPG3: [],
                    readjustedEPG: [],
                    readjustedEPGDetails: [],
                    sponsoringEPG: [],
                    sttEPG: [],
                    talkEPG: [],
                },
                data2: {},
                needZoomUpdate: true,
                ready: false,
            };
        }

        case TimelineActionTypes.ZOOM_IN: {
            const presentation = { ...state.presentation };
            const zoom = getNewZoom(state.criterias, state.presentation.zoom, 1);

            Logger.debug({ after: zoom, before: presentation.zoom }, 'Zoomed in');
            presentation.zoom = zoom;
            return { ...state, presentation };
        }

        case TimelineActionTypes.ZOOM_OUT: {
            const presentation = { ...state.presentation };
            const zoom = getNewZoom(state.criterias, state.presentation.zoom, -1);

            Logger.debug({ after: zoom, before: presentation.zoom }, 'Zoomed out');
            presentation.zoom = zoom;
            return { ...state, presentation };
        }

        case TimelineActionTypes.WIP: {
            return { ...state, workInProgress: action.payload };
        }

        default: {
            return state;
        }
    }
};

/**
 * Get zoom and min/max statuses.
 *
 * @param state       Curent timeline criterias
 * @param actual      Actual zoom
 * @param zoomInOrOut Zoom out operation (-1) or zoom in operation (1) or 0 for no operation.
 */
function getNewZoom(state: ITimelineCriteriasState, actual: IZoom, zoomInOrOut: -1 | 0 | 1): IZoom {
    const zooms = GetZooms(state);

    const getIndex = (tested: IZoom) => {
        let res = -1;

        zooms.some((v: IZoom, i: number) => {
            if (
                v.isMax === tested.isMax &&
                v.isMin === tested.isMin &&
                v.spanLength === tested.spanLength &&
                v.spanSubsections === tested.spanSubsections &&
                v.spanWidth === tested.spanWidth
            ) {
                res = i;
                return true;
            }
            return false;
        });
        return res;
    };

    const index = getIndex(actual);
    let zoom = actual;

    if (index === -1) {
        zoom = zooms[0];
    } else if (zoomInOrOut === 1 && index < zooms.length - 1) {
        zoom = zooms[index + 1];
    } else if (zoomInOrOut === -1 && index > 0) {
        zoom = zooms[index - 1];
    }

    return zoom;
}

/**
 * Get start zoom for timeline criterias.
 */
function getStartZoom(state: ITimelineCriteriasState): IZoom {
    const seconds = (state.end.getTime() - state.start.getTime()) / 1000;
    const zooms = GetZooms(state);
    let res = zooms[0];
    const container = document.getElementById('scrollerContainer');
    const initial = GetInitialZoom(GetAllZooms());

    if (isNull(container)) {
        return res;
    }
    const availWidth = container.getBoundingClientRect().width;

    if (availWidth === 0) {
        return res;
    }
    let prevZoom: IZoom | null = null;
    zooms.reverse().some((zoom) => {
        const spans = Math.ceil(seconds / zoom.spanLength);
        const neededWidth = spans * zoom.spanWidth;

        if (neededWidth <= availWidth) {
            res = isNull(prevZoom) ? zoom : prevZoom;
            return true;
        }
        prevZoom = zoom;
        return false;
    });
    if (initial.spanLength < res.spanLength) {
        res = initial;
    }
    return res;
}

export { reducer as TimelineReducer };

Backend.getInstance().Bind('epg', (incoming: IFromCommand) => {
    const cmd = incoming as EPG;

    if (cmd.GetName() !== 'epg') {
        return;
    }
    (store as Store).dispatch(
        newAction(TimelineActionTypes.SET_EPGS, {
            annotations: cmd.getAnnotations(),
            arpp: cmd.getARPP(),
            audiences: cmd.getAudiences(),
            background: cmd.getBackground(),
            backgroundstg: cmd.getBackgroundSTG(),
            broadcaster: cmd.getBroadcaster(),
            crossbucket: cmd.getCrossBucket(),
            crossbucketstg: cmd.getCrossBucketstg(),
            declarative: cmd.getDeclarative(),
            declarativeRadio: cmd.getDeclarativeRadio(),
            extract: cmd.getResultExtract(),
            leonV1: cmd.getLeonV1(),
            mnionly: cmd.getMNIONLY(),
            mnmProcessed: cmd.getMNMProcessed(),
            mnmRaw: cmd.getMNMRaw(),
            mnmxb: cmd.getMNMXB(),
            music: cmd.getMusic(),
            newEPG: cmd.getEPG(),
            ocr: cmd.getOCR(),
            ocrTitle: cmd.getOCRTitle(),
            processed: cmd.getResultProcessed(),
            raw: cmd.getBrutPhonosense(),
            readjusted: cmd.getReadjusted(),
            sponsoring: cmd.getSponsoring(),
            stt: cmd.getStt(),
            talk: cmd.getTalk(),
        }),
    );
});
